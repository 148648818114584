import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';

const MultiLineAlert = ({ messages, ...restProps }) => (
  <Alert {...restProps}>
    {messages.map((message) => (
      <Typography key={message} sx={{ mt: 0.7 }}>
        {message}
      </Typography>
    ))}
  </Alert>
);

export default MultiLineAlert;
