import { forwardRef, useCallback, useState } from 'react';
import clsx from 'clsx';
import { truncate } from 'lodash';
import { Draggable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import { ConfirmDialog } from 'components/Forms';
import Typography from '@mui/material/Typography';
import { Blue, Gray } from 'components/App/colors';
import DeleteIcon from '@mui/icons-material/Delete';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import EditArea from 'components/Regulators/EditableTreeItem/EditArea';

const CustomContentComponent = forwardRef(
  (
    {
      type,
      nodeId,
      nodeText,
      isSection,
      deleteNode,
      updateNodeText,
      provided,
      isDragging,
      classes,
      className,
      index,
    },
    ref
  ) => {
    const isOdd = index % 2 === 1;
    const sx = useSx(isDragging, isOdd);
    const [isDialogOpen, setDialogOpen] = useState(false);
    const { expanded, selected, focused, handleExpansion } = useTreeItem(nodeId);

    const iconElement = !isSection ? null : expanded ? <ArrowDropDownIcon /> : <ArrowRightIcon />;

    const nodeClassName = clsx(className, classes.root, {
      [classes.expanded]: expanded,
      [classes.selected]: selected,
      [classes.focused]: focused,
    });

    const mergeRefs = useCallback((refs) => {
      return (value) => {
        refs.forEach((ref) => {
          if (typeof ref === 'function') {
            ref(value);
          } else if (ref != null) {
            ref.current = value;
          }
        });
      };
    }, []);

    const onCancel = () => {
      setDialogOpen(false);
    };

    const onConfirm = () => {
      deleteNode(nodeId, type);
      onCancel();
    };

    const displayType = type === 'AscentModule' ? 'Module' : type;

    return (
      <Box
        sx={sx.root}
        className={nodeClassName}
        ref={mergeRefs([ref, provided.innerRef])}
        {...provided.draggableProps}
      >
        <Box {...provided.dragHandleProps} sx={sx.dragIconBox}>
          <DragIndicatorIcon data-testid="drag-icon" sx={sx.dragIcon} />
        </Box>
        <Box className={classes.iconContainer} onClick={handleExpansion} sx={sx.ecIcon}>
          {iconElement}
        </Box>
        {isSection ? (
          <EditArea
            variant="body2"
            text={nodeText}
            onClick={handleExpansion}
            onSubmit={(value) => (type === 'Rule' ? null : updateNodeText(nodeId, type, value))}
          />
        ) : (
          <Typography
            variant="body2"
            data-testid="label-area"
            sx={{ flexGrow: 1 }}
            onClick={handleExpansion}
          >
            {truncate(nodeText, { length: 150 })}
          </Typography>
        )}
        <DeleteIcon data-testid="delete-icon" sx={sx.delIcon} onClick={() => setDialogOpen(true)} />
        <ConfirmDialog
          isOpen={isDialogOpen}
          title={`Delete ${displayType}`}
          description={`Are you sure you want to delete this ${displayType}?`}
          onConfirm={onConfirm}
          onCancel={onCancel}
          confirmText={`Delete ${displayType}`}
        />
      </Box>
    );
  }
);

const EditableTreeItem = ({
  type,
  index,
  nodeId,
  nodeText,
  children,
  isSection,
  deleteNode,
  updateNodeText,
}) => (
  <Draggable draggableId={nodeId} index={index}>
    {(provided, snapshot) => (
      <TreeItem
        nodeId={nodeId}
        children={children}
        ContentComponent={CustomContentComponent}
        ContentProps={{
          type,
          nodeId,
          nodeText,
          isSection,
          deleteNode,
          updateNodeText,
          provided,
          index,
          isDragging: snapshot.isDragging,
        }}
      />
    )}
  </Draggable>
);

const useSx = (isDragging, isOdd) => ({
  root: {
    padding: '0.25em 0.35em 0.25em 0.35em !important',
    backgroundColor: isDragging ? Blue[50] : isOdd ? Gray[200] : '#fff',
    height: '2.2em',
    overflow: 'hidden',
  },
  dragIconBox: {
    marginLeft: '0.5em',
    marginTop: '0.4em',
  },
  dragIcon: {
    fontSize: '1em',
    color: Gray[500],
    cursor: 'all-scroll',
  },
  ecIcon: {
    marginTop: '0.1em',
  },
  delIcon: {
    fontSize: '1.05em',
    color: Gray[500],
    marginLeft: '0.4em',
    marginTop: '0.1em',
  },
});

export default EditableTreeItem;
