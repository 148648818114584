import { useState } from 'react';
import { HashRouter, Navigate, Routes, Route } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import { useAuth0 } from '@auth0/auth0-react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import theme from './theme';

import Header from './Header';
import NavDrawer from './NavDrawer';
import LoginPage from 'components/LoginPage';
import { Regulator, Regulators, RegulatorFull, RuleBookEdit } from 'components/Regulators';
import { LegacyRule, Rule, RuleVersion } from 'components/Rules';
import routes from 'routes';

import { StyleCacheProvider } from 'contexts/styleCache';
import { ToastProvider } from 'contexts/toast';

const App = () => {
  const [isNavDrawerOpen, setNavDrawerOpen] = useState(false);
  const toggleNavDrawer = () => setNavDrawerOpen(!isNavDrawerOpen);

  const { isAuthenticated } = useAuth0();
  const sx = useSx();

  if (!isAuthenticated) return <LoginPage />;

  return (
    <HashRouter>
      <StyleCacheProvider>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <ToastProvider>
              <CssBaseline />
              <Header isNavDrawerOpen={isNavDrawerOpen} toggleNavDrawer={toggleNavDrawer} />
              <NavDrawer isNavDrawerOpen={isNavDrawerOpen} toggleNavDrawer={toggleNavDrawer} />
              <Box component="main" sx={sx.main}>
                <Routes>
                  <Route
                    exact
                    path="/"
                    replace
                    element={<Navigate to={routes.regulators.path} />}
                  />

                  <Route path={routes.regulators.path} exact element={<Regulators />} />
                  <Route path={routes.regulator.full.path} element={<RegulatorFull />} />
                  <Route path={routes.regulator.edit.path} element={<RuleBookEdit />} />

                  <Route path={routes.regulator.path} element={<Regulator />}>
                    <Route path={routes.regulator.legacyRule.path} index element={<LegacyRule />} />
                    <Route path={routes.regulator.rule.path} element={<Rule />}>
                      <Route
                        path={routes.regulator.rule.ruleVersion.path}
                        element={<RuleVersion />}
                      />
                    </Route>
                  </Route>

                  <Route path="*" replace element={<Navigate to={routes.regulators.path} />} />
                </Routes>
              </Box>
            </ToastProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </StyleCacheProvider>
    </HashRouter>
  );
};

const useSx = () => ({
  main: {
    flexGrow: 1,
    mt: 8,
    maxWidth: '100%',
  },
});

export default App;
