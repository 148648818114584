import { useState } from 'react';
import useForm from 'hooks/useForm';
import { useToast } from 'contexts/toast';
import { useMutation } from '@apollo/client';
import {
  SUPPORTING_INFORMATION_VERSION_CREATE_MUTATION,
  SUPPORTING_INFORMATION_VERSION_UPDATE_MUTATION,
} from 'components/SupportingInformations/queries';
import SupportingInformationVersionUpsertFormView from 'components/SupportingInformations/SupportingInformationVersionUpsertForm/index.view';
import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';

const buildFormDefaults = (siVersion, ruleVersion) => ({
  aic: siVersion?.aic || '',
  number: siVersion?.number || '',
  content: siVersion?.content || '',
  endsAt: toLocalizedDate(siVersion?.endsAt),
  startsAt: toLocalizedDate(siVersion?.startsAt || ruleVersion?.startsAt),
});

const SupportingInformationVersionUpsertForm = ({
  onClose,
  isEditing,
  breadCrumbsLabels,
  refetchQueries = [],
  supportingInformationVersion,
  supportingInformationId,
  ruleVersion = {},
  disableDates = false,
}) => {
  const toastMessage = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm(buildFormDefaults(supportingInformationVersion, ruleVersion));
  const [createSiVersion] = useMutation(SUPPORTING_INFORMATION_VERSION_CREATE_MUTATION);
  const [updateSiVersion] = useMutation(SUPPORTING_INFORMATION_VERSION_UPDATE_MUTATION);
  const upsertSiVersion = isEditing ? updateSiVersion : createSiVersion;
  const entityKey = isEditing ? 'id' : 'supportingInformationId';
  const entityKeyValue = isEditing ? supportingInformationVersion.id : supportingInformationId;
  const mutationKey = isEditing
    ? 'updateSupportingInformationVersion'
    : 'createSupportingInformationVersion';

  const handleSubmit = async () => {
    form.setErrors([]);
    setIsSubmitting(true);

    try {
      const {
        data: {
          [mutationKey]: { errors },
        },
      } = await upsertSiVersion({
        variables: {
          ...form.values,
          startsAt: toISODateString(form.values.startsAt),
          endsAt: toISODateString(form.values.endsAt),
          [entityKey]: entityKeyValue,
        },
        refetchQueries,
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'rule' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
        },
      });

      setIsSubmitting(false);

      if (errors.length) {
        return form.setErrors(errors);
      }

      onClose();

      toastMessage(
        'success',
        `Supporting information version has been ${isEditing ? 'updated' : 'created'} successfully`
      );
    } catch (error) {
      setIsSubmitting(false);

      form.setErrors([error.message]);
    }
  };

  return (
    <SupportingInformationVersionUpsertFormView
      form={form}
      onClose={onClose}
      isEditing={isEditing}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      breadCrumbsLabels={breadCrumbsLabels}
      disableDates={disableDates}
    />
  );
};

export default SupportingInformationVersionUpsertForm;
