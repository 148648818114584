import Box from '@mui/material/Box';
import MarkdownPreview from 'react-markdown';
import rehypeRaw from 'rehype-raw';

function disableCodeIndented() {
  this.data('micromarkExtensions', [
    { disable: { null: ['codeIndented', 'codeFenced', 'codeText'] } },
  ]);
}

const RegulatorView = ({ regulator, loading, error }) => {
  const sx = useSx();

  if (loading) return null;

  return (
    <Box sx={sx.root}>
      <h1>{regulator.name}</h1>

      {regulator.sections.nodes.map((section) => (
        <>
          <h2>{section.name}</h2>

          {section.sections.nodes.map((section) => (
            <>
              <h3>{section.name}</h3>

              {section.rules.nodes.map((rule) => (
                <>
                  <h4>
                    {rule.number} {rule.title}
                  </h4>

                  <MarkdownPreview
                    children={rule.text}
                    remarkPlugins={[disableCodeIndented]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      li: ({ children }) => <li style={{ listStyle: 'none' }}>{children}</li>,
                    }}
                  />
                </>
              ))}
            </>
          ))}
        </>
      ))}
    </Box>
  );
};

const useSx = () => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    zIndex: 99999,
    background: 'white',
  },
});

export default RegulatorView;
