import useQueryParams from 'hooks/useQuery';
import VersionDeleteForm from 'components/VersionDeleteForm';
import { RULE_CHILDREN_QUERY } from 'components/Rules/queries';
import { SUPPORTING_INFORMATION_VERSION_DELETE_MUTATION } from 'components/SupportingInformations/queries';
import DeleteSupportingInformationVersionView from 'components/SupportingInformations/DeleteSupportingInformationVersion/index.view';

const DeleteSupportingInformationVersion = (props) => {
  const queryParams = useQueryParams();
  const ruleId = queryParams.get('ruleId');

  return (
    <VersionDeleteForm
      Component={DeleteSupportingInformationVersionView}
      props={props}
      deleteMutationKey={'deleteSupportingInformationVersion'}
      mutationQuery={SUPPORTING_INFORMATION_VERSION_DELETE_MUTATION}
      refetchQueries={[
        {
          query: RULE_CHILDREN_QUERY,
          variables: { id: ruleId, startsAt: props.ruleVersion?.startsAt },
        },
      ]}
    />
  );
};

export default DeleteSupportingInformationVersion;
