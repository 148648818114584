import { useState } from 'react';
import useForm from 'hooks/useForm';
import { useToast } from 'contexts/toast';
import { useMutation } from '@apollo/client';
import {
  REQUIREMENT_VERSION_CREATE_MUTATION,
  REQUIREMENT_VERSION_UPDATE_MUTATION,
} from 'components/Requirements/queries';
import RequirementVersionUpsertFormView from 'components/Requirements/RequirementVersionUpsertForm/index.view';
import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';

const buildFormDefaults = (reqVersion, ruleVersion) => ({
  aic: reqVersion?.aic || '',
  number: reqVersion?.number || '',
  content: reqVersion?.content || '',
  endsAt: toLocalizedDate(reqVersion?.endsAt),
  startsAt: toLocalizedDate(reqVersion?.startsAt || ruleVersion?.startsAt),
  frequency: reqVersion?.frequency || '',
  summary: reqVersion?.summary || '',
});

const RequirementVersionUpsertForm = ({
  onClose,
  isEditing,
  requirementVersion,
  requirementId,
  breadCrumbsLabels,
  refetchQueries = [],
  ruleVersion = {},
  disableDates = false,
}) => {
  const toastMessage = useToast();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const form = useForm(buildFormDefaults(requirementVersion, ruleVersion));
  const [createReqVersion] = useMutation(REQUIREMENT_VERSION_CREATE_MUTATION);
  const [updateReqVersion] = useMutation(REQUIREMENT_VERSION_UPDATE_MUTATION);
  const upsertReqVersion = isEditing ? updateReqVersion : createReqVersion;
  const entityKey = isEditing ? 'id' : 'requirementId';
  const entityKeyValue = isEditing ? requirementVersion.id : requirementId;
  const mutationKey = isEditing ? 'updateRequirementVersion' : 'createRequirementVersion';

  const handleSubmit = async () => {
    form.setErrors([]);
    setIsSubmitting(true);

    try {
      const {
        data: {
          [mutationKey]: { errors },
        },
      } = await upsertReqVersion({
        variables: {
          ...form.values,
          startsAt: toISODateString(form.values.startsAt),
          endsAt: toISODateString(form.values.endsAt),
          [entityKey]: entityKeyValue,
        },
        refetchQueries,
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'rule' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
        },
      });

      setIsSubmitting(false);

      if (errors.length) {
        return form.setErrors(errors);
      }

      onClose();

      toastMessage(
        'success',
        `Requirement version has been ${isEditing ? 'updated' : 'created'} successfully`
      );
    } catch (error) {
      setIsSubmitting(false);

      form.setErrors([error.message]);
    }
  };

  return (
    <RequirementVersionUpsertFormView
      form={form}
      onClose={onClose}
      isEditing={isEditing}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      breadCrumbsLabels={breadCrumbsLabels}
      disableDates={disableDates}
    />
  );
};

export default RequirementVersionUpsertForm;
