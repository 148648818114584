import { useState } from 'react';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import ConfirmDialog from 'components/Forms/ConfirmDialog';
import CloseIcon from '@mui/icons-material/Close';

const DismissableAlert = ({
  confirmMessage,
  confirmTitle,
  children,
  onClose = () => {},
  severity,
  visible = true,
  ...restProps
}) => {
  const [isVisible, setIsVisible] = useState(visible);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  const handleDismissClick = () => {
    if (!confirmMessage) return handleClose();

    setIsDialogOpen(true);
  };

  if (!isVisible) return null;

  return (
    <>
      <Alert
        {...restProps}
        sx={{ alignItems: 'center', my: 1 }}
        severity={severity}
        action={
          <Button
            sx={{ marginRight: '9px' }}
            color="inherit"
            onClick={handleDismissClick}
            endIcon={<CloseIcon fontSize="small" />}
          >
            Dismiss
          </Button>
        }
      >
        {children}
      </Alert>
      <ConfirmDialog
        isOpen={isDialogOpen}
        title={confirmTitle}
        description={confirmMessage}
        onConfirm={handleClose}
        onCancel={() => setIsDialogOpen(false)}
      />
    </>
  );
};

export default DismissableAlert;
