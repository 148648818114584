import React from 'react';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const AlphaTabFilter = ({ onChange, currentTab }) => {
  const sx = useSx();
  const letters = 'abcdefghijklmnopqrstuvwxyz';
  let tabs = [{ value: '0-9', label: '0-9' }];

  tabs = [...tabs, ...letters.split('').map((l) => ({ value: l, label: l }))];

  return (
    <Tabs value={currentTab || '0-9'} onChange={onChange}>
      {tabs.map((tab) => (
        <Tab
          sx={sx.tab}
          data-testid={`tab-${tab.value}`}
          key={tab.value}
          value={tab.value}
          label={tab.label}
          className="tab"
        />
      ))}
    </Tabs>
  );
};

const useSx = () => ({
  tab: {
    minWidth: '10px',
    paddingLeft: '10px',
    paddingRight: '10px',
    borderRadius: '10px',
    color: 'secondary.main',
    fontSize: '20px',
    '&:hover': {
      backgroundColor: 'primary.light',
    },
    '&:focus': {
      color: 'primary.dark',
      outline: '2px solid primary.main',
      outlineOffset: '2px',
    },
    '&.Mui-selected': {
      backgroundColor: 'secondary.light',
      color: 'secondary.dark',
    },
    '&.Mui-disabled': {
      opacity: 0.5,
      cursor: 'not-allowed',
    },
  },
});

export default AlphaTabFilter;
