import { startCase } from 'lodash';

export const carryForward = (passageVersion, ruleVersion) =>
  passageVersion.ruleVersion.id !== ruleVersion.id;

export const typeName = (obj) => startCase(obj.__typename);

export const parentTypeName = (obj) => typeName(obj).replace(/ Version$/, '');

export const passageContent = ({ content, baseContent, taskContent }) =>
  taskContent || baseContent || content;
