import { useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import IconButton from '@mui/material/IconButton';
import AppBar from '@mui/material/AppBar';
import PersonIcon from '@mui/icons-material/Person';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { usePopupState } from 'hooks/usePopupState';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ClickAwayListener from '@mui/base/ClickAwayListener';
import LaunchIcon from '@mui/icons-material/Launch';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import { ConfirmDialog } from 'components/Forms';
import useForm from 'hooks/useForm';
import useRoutes from 'hooks/useRoutes';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

const Header = ({ isNavDrawerOpen, toggleNavDrawer, navDrawerWidth = 200 }) => {
  const sx = useSx();
  const anchorRef = useRef(null);
  const { isOpen, handleClose, handleListKeyDown, handleToggle } = usePopupState(anchorRef);
  const { logout } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <AppBar position="fixed" sx={sx.appBar} open={isNavDrawerOpen} elevation={0}>
      <Toolbar sx={sx.toolbar}>
        <IconButton
          data-testid="navDrawer-toggler"
          aria-label="toggle drawer"
          edge="start"
          onClick={toggleNavDrawer}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          data-testid="site-header"
          component="div"
          variant="subtitle1"
          noWrap
          sx={sx.title}
        >
          Canonical Data Service
        </Typography>

        <Tooltip title="Go to record by ID" placement="top">
          <IconButton aria-label="record-by-id" onClick={() => setIsModalOpen(true)}>
            <LaunchIcon />
          </IconButton>
        </Tooltip>

        <IconButton onClick={handleToggle} ref={anchorRef}>
          <PersonIcon id="user-icon" />
        </IconButton>

        <Popper
          open={isOpen}
          anchorEl={anchorRef.current}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
              }}
            >
              <Paper sx={{ borderRadius: '14px' }}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={isOpen}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <MenuItem
                      onClick={() => logout({ returnTo: window.location.origin })}
                      id="sign-out"
                    >
                      <Typography variant="body2"> Sign Out </Typography>
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Toolbar>
      <DeepLinkModal isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
    </AppBar>
  );
};

const DeepLinkModal = ({ isOpen, setIsModalOpen }) => {
  const navigate = useNavigate();
  const routes = useRoutes();
  const form = useForm({ uuid: '' });

  const handleClose = () => {
    form.reset();
    setIsModalOpen(false);
  };

  const handleConfirm = async () => {
    form.setErrors([]);
    const uuid = form.values.uuid.trim();

    if (!uuid) return form.setErrors(['ID is required']);

    try {
      const response = await fetch(routes.generateUrl('deepLink', { uuid }));
      const data = await response.json();

      if (data.recordDeepLink) {
        const url = new URL(data.recordDeepLink);

        handleClose();

        return navigate(url.hash.split('#')[1], { replace: true });
      }

      return form.setErrors(['Invalid UUID']);
    } catch (error) {
      form.setErrors([error.message]);
    }
  };

  return (
    <ConfirmDialog
      fullWidth
      isOpen={isOpen}
      onCancel={handleClose}
      title="Go to Record"
      description="Enter the ID of a record to which you'd like to navigate"
      confirmText="Navigate"
      onConfirm={handleConfirm}
    >
      {!!form.errors.length && (
        <Alert severity="error" sx={{ display: 'flex', alignItems: 'center' }}>
          {form.errors[0]}
        </Alert>
      )}

      <TextField
        autoFocus
        margin="dense"
        id="name"
        label="uuid"
        fullWidth
        variant="outlined"
        value={form.values.uuid}
        {...form.props.uuid}
      />
    </ConfirmDialog>
  );
};

const useSx = () => ({
  appBar: {
    display: 'flex',
    backgroundColor: 'primary.background',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'primary.main',
  },
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 2.5,
  },
  title: {
    mr: 2,
    marginLeft: 2,
    display: {
      md: 'flex',
      flexGrow: 1,
      color: 'primary.main',
    },
  },
});

export default Header;
