import { useParams, Navigate } from 'react-router-dom';
import { useEnv } from 'contexts/env';
import { useLocationSearch } from 'utils/searchParams';
import useRoutes from 'hooks/useRoutes';

const LegacyRuleRedirect = () => {
  const env = useEnv();
  const routes = useRoutes();
  const { regulatorId } = useParams();
  const { ruleId, ruleVersionId } = useLocationSearch().params;

  if (!env.FF_FORCE_NEW_RULE_PAGE || !ruleId) return null;

  const redirectUrl = ruleVersionId
    ? routes.generateUrl('regulator.ruleVersion', { regulatorId, ruleId, ruleVersionId })
    : routes.generateUrl('regulator.rule', { regulatorId, ruleId });

  return <Navigate to={redirectUrl} replace />;
};

export default LegacyRuleRedirect;
