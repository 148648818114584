import { useQuery } from '@apollo/client';
import Alert from '@mui/material/Alert';
import LinearProgress from '@mui/material/LinearProgress';

import { compactObject } from 'utils/object';
import { pageToCursor, cursorToPage, useLocationSearch } from 'utils/searchParams';

import { REGULATORS_QUERY } from 'components/Regulators/queries';
import RegulatorsView from 'components/Regulators/Regulators/index.view';

const Regulators = () => {
  const {
    params: { after, prefix },
    replaceSearchParams,
  } = useLocationSearch();

  const { loading, error, data } = useQuery(REGULATORS_QUERY, {
    variables: { after: after, prefix: prefix || 'a', type: 'workflow' },
  });

  if (loading) {
    const waitMessage = 'This might take a bit, hang tight...';

    return (
      <>
        <Alert severity="info" sx={{ display: 'flex', alignItems: 'center' }}>
          {waitMessage}
        </Alert>
        <LinearProgress data-testid="loading" />
      </>
    );
  }

  if (error) return <Alert severity="error">Something went wrong :(</Alert>;

  const {
    nodes: regulators,
    pageInfo: { pageSize, pageCount },
  } = data.regulators;

  const page = cursorToPage(after, pageSize);

  const setPage = (newPage) => {
    const newAfter = pageToCursor(newPage, pageSize);

    replaceSearchParams(compactObject({ after: newAfter, prefix: prefix }));
  };

  function tabsOnChange(_e, tabval) {
    replaceSearchParams(compactObject({ prefix: tabval }));
  }

  return (
    <RegulatorsView
      regulators={regulators}
      page={page}
      pageCount={pageCount}
      setPage={setPage}
      tabsOnChange={tabsOnChange}
      currentTab={prefix || 'a'}
    />
  );
};

export default Regulators;
