import React from 'react';
import Box from '@mui/material/Box';
import { useAuth0 } from '@auth0/auth0-react';
import LoginIcon from '@mui/icons-material/Login';
import LoadingButton from '@mui/lab/LoadingButton';
import ascentLogo from 'assets/images/ascent-logo.png';

const LoginPage = () => {
  const sx = useSx();
  const { loginWithRedirect, isLoading } = useAuth0();

  return (
    <Box sx={sx.btnWrapper}>
      <Box sx={sx.logo}>
        <img src={ascentLogo} alt="Ascent" width="246" height="40" />
      </Box>

      <LoadingButton
        id="sign-in"
        sx={sx.btn}
        variant="contained"
        size="large"
        onClick={() => loginWithRedirect({ appState: { targetUrl: window.location.href } })}
        loading={isLoading}
        endIcon={<LoginIcon />}
        loadingPosition="end"
      >
        {isLoading ? 'Signing In' : 'Sign In to CDS'}
      </LoadingButton>
    </Box>
  );
};

const useSx = () => ({
  btnWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
  },
  logo: {
    marginBottom: '2rem',
  },
});

export default LoginPage;
