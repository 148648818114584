// Libs
import React, { createRef, memo, useState } from 'react';
import memoize from 'memoize-one';
import { FixedSizeList, areEqual } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { matchSorter } from 'match-sorter';
import { Link as RouterLink } from 'react-router-dom';

// Mui Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

// Mui Icons
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ClearIcon from '@mui/icons-material/Clear';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SubdirectoryArrowRightOutlinedIcon from '@mui/icons-material/SubdirectoryArrowRightOutlined';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';

const Tree = ({
  data,
  editPath,
  generateUrl,
  getLabel,
  getChildrenType,
  getChildren,
  leafTypes,
  onClickAddNew,
  selectedId,
  sx: sxProps,
}) => {
  const defaultOpenedNodes = { [data.id]: true };

  const [searchInput, setSearchInput] = useState('');
  const [openedNodesMap, setOpenNodes] = useState(defaultOpenedNodes);
  const [isAllOpen, setIsAllOpen] = useState(true);
  const listRef = createRef();

  const isItemOpen = (item) => {
    if (!item) return true;
    if (!isItemOpen(item.parent)) return false;

    return openedNodesMap.hasOwnProperty(item.id) ? openedNodesMap[item.id] : isAllOpen;
  };

  const toggleItemOpen = (id) =>
    setOpenNodes({
      ...openedNodesMap,
      [id]: openedNodesMap.hasOwnProperty(id) ? !openedNodesMap[id] : !isAllOpen,
    });

  const expandAll = () => {
    setIsAllOpen(true);
    setOpenNodes(defaultOpenedNodes);
    setSearchInput('');
  };

  const collapseAll = () => {
    setIsAllOpen(false);
    setOpenNodes(defaultOpenedNodes);
    setSearchInput('');
  };

  const onlyLeafNodes = (item) => leafTypes.includes(item.type);

  const flattenedData = memoizedFlattenedData(data, null, leafTypes, {
    getLabel,
    getChildrenType,
    getChildren,
    onClickAddNew,
  });

  const getItemsForSearch = () =>
    matchSorter(flattenedData.filter(onlyLeafNodes), searchInput, { keys: ['pathLabel'] });

  const getVisibleItems = () => flattenedData.filter((item) => isItemOpen(item.parent));
  const items = !!searchInput ? getItemsForSearch() : getVisibleItems();
  const selectedIndex = Math.max(
    0,
    items.findIndex((item) => item.id === selectedId)
  );

  const itemData = {
    generateUrl,
    isItemOpen,
    items,
    leafTypes,
    searchInput,
    selectedId,
    selectedIndex,
    toggleItemOpen,
  };

  const onSearchChange = (e) => {
    setSearchInput(e.target.value);
    listRef.current.scrollToItem(0);
  };
  const onSearchClear = () => {
    setSearchInput('');
    listRef.current.scrollToItem(selectedIndex);
  };

  const sx = useSx();

  return (
    <Box sx={{ ...sx.root, ...sxProps }}>
      <Typography flexShrink={1} flex={0} sx={sx.title} data-testid="tree-title">
        {getLabel(data)}
      </Typography>
      <Box flexShrink={1}>
        <TextField
          variant="standard"
          size="small"
          fullWidth
          placeholder={`Search for a ${leafTypes.join(', ')}...`}
          value={searchInput}
          onChange={onSearchChange}
          InputProps={{
            endAdornment: searchInput && (
              <IconButton onClick={onSearchClear}>
                <ClearIcon fontSize="small" />
              </IconButton>
            ),
          }}
        />
      </Box>
      <Box flexShrink={1} marginY={1} display="flex" justifyContent="space-between">
        <Box>
          <Button onClick={expandAll} sx={{ marginRight: 1 }}>
            <UnfoldMoreIcon sx={sx.actionIcon} />
            Expand All
          </Button>
          <Button onClick={collapseAll}>
            <UnfoldLessIcon sx={sx.actionIcon} />
            Collapse All
          </Button>
        </Box>
        <Box display="flex" alignItems="flex-end">
          {editPath && (
            <Button component={RouterLink} to={editPath}>
              <EditOutlinedIcon sx={sx.actionIcon} />
              Edit
            </Button>
          )}
        </Box>
      </Box>
      <Box flexGrow={1}>
        <AutoSizer>
          {({ height, width }) => (
            <FixedSizeList
              ref={listRef}
              height={height || 900}
              width={width || 900}
              itemCount={items.length}
              itemData={itemData}
              itemSize={32}
              initialScrollOffset={selectedIndex * 32}
            >
              {Row}
            </FixedSizeList>
          )}
        </AutoSizer>
      </Box>
    </Box>
  );
};

const Row = memo(({ data, index, style }) => {
  const { items, leafTypes, selectedIndex, searchInput, generateUrl, isItemOpen, toggleItemOpen } =
    data;
  const item = items[index];
  const selected = index === selectedIndex;
  const depth = searchInput ? 0 : item.depth;
  const sx = useSx();
  const selectedProps = selected ? { 'data-testid': 'tree-selected' } : {};

  if (leafTypes.includes(item.type))
    return (
      <Link
        display="flex"
        component={RouterLink}
        to={generateUrl(item.id)}
        style={style}
        sx={sx.item(depth, selected)}
        color="inherit"
        {...selectedProps}
      >
        <ArticleOutlinedIcon sx={sx.itemIcon} />
        {item.label} <Box sx={sx.itemPath}>{searchInput && item.pathLabel}</Box>
      </Link>
    );

  if (item.isAddPrompt)
    return (
      <Box
        display="flex"
        alignItems="center"
        style={style}
        sx={sx.item(depth)}
        onClick={item.onClick}
      >
        <SubdirectoryArrowRightOutlinedIcon sx={sx.itemIcon} />
        <Button>{item.label}</Button>
      </Box>
    );

  return (
    <Box
      display="flex"
      alignItems="center"
      style={style}
      sx={sx.item(depth)}
      onClick={() => toggleItemOpen(item.id)}
    >
      {isItemOpen(item) ? (
        <KeyboardArrowDownIcon fontSize="small" sx={sx.itemIcon} />
      ) : (
        <ChevronRightIcon fontSize="small" sx={sx.itemIcon} />
      )}
      {item.label}
    </Box>
  );
}, areEqual);

const flattenData = (parentNode, parentItem, leafTypes, fns) => {
  let flattened = [];

  for (var childNode of fns.getChildren(parentNode) || []) {
    const childItem = createItem(childNode, parentItem, fns);

    flattened.push(childItem);

    if (fns.getChildren(childNode)?.length > 0) {
      flattened = flattened.concat(flattenData(childNode, childItem, leafTypes, fns));
    } else if (!leafTypes.includes(childNode.__typename)) {
      flattened.push(createItemForAddPrompt(childNode, childItem, fns));
    }
  }

  flattened.push(createItemForAddPrompt(parentNode, parentItem, fns));

  return flattened;
};

const memoizedFlattenedData = memoize((...args) => flattenData(...args));

const createItem = (node, parentItem, fns) => ({
  id: node.id,
  type: node.__typename,
  label: fns.getLabel(node),
  pathLabel: parentItem ? `${parentItem.pathLabel} > ${fns.getLabel(node)}` : fns.getLabel(node),
  hasChildren: (fns.getChildren(node) || []).length > 0,
  parent: parentItem,
  depth: parentItem ? parentItem.depth + 1 : 0,
});

const createItemForAddPrompt = (parentNode, parentItem, fns) => ({
  id: parentNode.id + '_add',
  label: `Add New ${fns.getChildrenType(parentNode)}`,
  hasChildren: false,
  isAddPrompt: true,
  onClick: () => fns.onClickAddNew(parentNode),
  parent: parentItem,
  depth: parentItem ? parentItem.depth + 1 : 0,
});

const useSx = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  title: {
    whiteSpace: 'break-spaces',
    paddingRight: 3,
    marginBottom: 1,
    fontSize: '1em',
    fontWeight: 600,
  },
  item: (depth, selected = false) => ({
    display: 'flex',
    color: 'text.primary',
    alignItems: 'center',
    fontSize: 16,
    cursor: 'pointer',
    borderRadius: 1,
    textDecoration: 'none',
    paddingLeft: depth * 2,
    ...(selected && {
      backgroundColor: 'secondary.light',
      color: 'secondary.dark',
    }),
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }),
  itemPath: {
    color: 'text.secondary',
    fontSize: 12,
    marginLeft: 2,
  },
  actionIcon: {
    height: 18,
    marginLeft: 0,
  },
  itemIcon: {
    height: 14,
    marginX: 0.5,
  },
});

export default Tree;
