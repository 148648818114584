import { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useParams, Navigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import LegacyRuleView from './index.view';

import { RULE_QUERY, RULE_VERSION_QUERY, RULE_CHILDREN_QUERY } from '../queries';
import { QUESTIONS_QUERY } from 'components/Questions/queries';
import { REGULATOR_TREE_QUERY } from 'components/Regulators/queries';
import { UPDATE_RULE_MUTATION } from 'components/Rules/queries';
import { useLocationSearch } from 'utils/searchParams';

import { useToast } from 'contexts/toast';
import { useEnv } from 'contexts/env';
import useRoutes from 'hooks/useRoutes';
import useForm from 'hooks/useForm';

const Centered = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
    {children}
  </Box>
);

const SelectRule = () => (
  <Centered>
    <Typography variant="h1">Select a rule to continue</Typography>
  </Centered>
);

const NotFound = () => (
  <Centered>
    <Alert severity="error">Unable to find the selected Rule</Alert>
  </Centered>
);

const FORM_DEFAULTS = {
  newAnswers: [],
  answerIds: [],
};

const LegacyRule = () => {
  const { params, setSearchParams } = useLocationSearch();
  const { ruleId, ruleVersionId } = params;
  const { regulatorId } = useParams();
  const [isUpdatingRule, setIsUpdatingRule] = useState(false);
  const toastMessage = useToast();
  const form = useForm(FORM_DEFAULTS);
  const env = useEnv();
  const routes = useRoutes();

  const refetchRuleQueries = [{ query: RULE_QUERY, variables: { id: ruleId } }];
  const refetchQueries = [
    ...refetchRuleQueries,
    { query: REGULATOR_TREE_QUERY, variables: { id: regulatorId } },
    { query: RULE_VERSION_QUERY, variables: { id: ruleVersionId } },
  ];

  const { values: formValues, setValues: setFormValues, setErrors: setFormErrors } = form;

  const {
    loading: isLoadingRule,
    error: fetchRuleError,
    data: { rule } = {},
  } = useQuery(RULE_QUERY, {
    variables: { id: ruleId },
    skip: !ruleId,
  });

  const {
    loading: isLoadingRuleVersion,
    refetch: refetchRuleVersion,
    data: { ruleVersion = {} } = {},
  } = useQuery(RULE_VERSION_QUERY, {
    variables: { id: ruleVersionId || rule?.currentVersion?.id },
    skip: !ruleId,
  });

  const {
    loading: isLoadingChildren,
    error: fetchChildrenError,
    data: { rule: { children = {} } = {} } = {},
  } = useQuery(RULE_CHILDREN_QUERY, {
    variables: {
      id: ruleId,
      startsAt: ruleVersion?.startsAt,
    },
    skip: !ruleVersion?.id,
  });

  const { data: { questions = {} } = {} } = useQuery(QUESTIONS_QUERY, {
    variables: { regulatorId },
    skip: !ruleId,
  });

  const [updateRule] = useMutation(UPDATE_RULE_MUTATION, {
    refetchQueries: [
      { query: RULE_QUERY, variables: { id: ruleId } },
      { query: QUESTIONS_QUERY, variables: { regulatorId } },
    ],
    awaitRefetchQueries: true,
  });

  useEffect(() => {
    const answerIds = rule?.answers?.map((answer) => answer.id) || [];

    setFormValues((values) => ({ ...values, answerIds }));
  }, [rule, setFormValues]);

  if (env.FF_FORCE_NEW_RULE_PAGE && !!ruleId) {
    const redirectUrl = ruleVersionId
      ? routes.generateUrl('regulator.ruleVersion', { regulatorId, ruleId, ruleVersionId })
      : routes.generateUrl('regulator.rule', { regulatorId, ruleId });

    return <Navigate to={redirectUrl} replace />;
  }

  const fetchRuleVersion = ({ id }) => {
    setSearchParams({ ruleVersionId: id });
    refetchRuleVersion({ id });
  };

  const handleRuleUpdate = async (values = formValues) => {
    setFormErrors([]);
    setIsUpdatingRule(true);

    try {
      const {
        data: {
          updateRule: { errors },
        },
      } = await updateRule({
        variables: {
          id: rule.id,
          ...values,
        },
      });

      setIsUpdatingRule(false);
      if (errors.length) {
        errors.map((error) => toastMessage('error', error));

        return setFormErrors(errors);
      }
      toastMessage('success', 'Rule updated successfully.');
    } catch (error) {
      setFormErrors([error.message]);
      toastMessage('error', error.message);
      setIsUpdatingRule(false);
    }
  };

  if (fetchRuleError) return <Alert severity="error">Something went wrong :(</Alert>;
  if (!ruleId) return <SelectRule />;
  if (!isLoadingRule && !rule) return <NotFound />;

  return (
    <LegacyRuleView
      fetchChildrenError={fetchChildrenError}
      fetchRuleVersion={fetchRuleVersion}
      form={form}
      isLoadingChildren={isLoadingChildren}
      isLoadingRule={isLoadingRule}
      isLoadingRuleVersion={isLoadingRuleVersion}
      isUpdatingRule={isUpdatingRule}
      questions={questions?.nodes}
      rule={rule}
      ruleChildren={children?.nodes || []}
      ruleVersion={ruleVersion}
      updateRule={handleRuleUpdate}
      refetchQueries={refetchQueries}
      refetchRuleQueries={refetchRuleQueries}
    />
  );
};

export default LegacyRule;
