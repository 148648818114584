import { default as ReactMarkdownPreview } from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import Box from '@mui/material/Box';

function disableCodeIndented() {
  this.data('micromarkExtensions', [
    { disable: { null: ['codeIndented', 'codeFenced', 'codeText'] } },
  ]);
}

const wrapWithPrecedingLists = (text) => {
  let returnText = text;
  let preText = text;

  if (text.match(/ +\*/)) {
    preText = preText.slice(2);

    while (preText.startsWith('  ')) {
      preText = preText.slice(2);
      returnText = `<ul>\n<li>\n\n${returnText}\n\n</li>\n<ul>`;
    }
  }

  return returnText;
};

const MarkdownPreview = ({ text }) => (
  <Box sx={useSx().root}>
    <ReactMarkdownPreview
      children={wrapWithPrecedingLists(text)}
      remarkPlugins={[disableCodeIndented]}
      rehypePlugins={[rehypeRaw]}
      components={{
        li: ({ children }) => <li style={{ listStyle: 'none' }}>{children}</li>,
      }}
    />
  </Box>
);

const useSx = () => ({
  root: {
    '& img': {
      maxWidth: '100%',
    },
  },
});

export default MarkdownPreview;
