import { isEqual } from 'lodash';
import { useMemo, useState, useCallback, useEffect } from 'react';

const makeValue = (target) => (target.type === 'checkbox' ? target.checked : target.value);

const useForm = (initValues) => {
  const [errors, setErrors] = useState([]);
  const [warnings, setWarnings] = useState([]);
  const [values, setValues] = useState(initValues);
  const [hasChanges, setHasChanges] = useState(false);

  const reset = useCallback(() => {
    setErrors([]);
    setWarnings([]);
    setValues(initValues);
    setHasChanges(false);
  }, [initValues]);

  const onChange = useCallback((name, eventOrValue) => {
    const value = eventOrValue?.target ? makeValue(eventOrValue.target) : eventOrValue;

    setValues((currentState) => ({ ...currentState, [name]: value }));
  }, []);

  useEffect(() => {
    if (isEqual(values, initValues)) {
      setHasChanges(false);
    } else {
      setHasChanges(true);
    }
  }, [values, initValues]);

  const props = useMemo(
    () =>
      Object.entries(initValues).reduce(
        (acc, [name]) => ({
          ...acc,
          [name]: {
            name,
            onChange: (value) => onChange(name, value),
            'data-testid': `input-${name}`,
          },
        }),
        {}
      ),
    [onChange, initValues]
  );

  return {
    reset,
    props,
    values,
    errors,
    warnings,
    setValues,
    setErrors,
    hasChanges,
    setWarnings,
  };
};

export default useForm;
