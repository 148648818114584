import { generatePath as routerGenerateUrl } from 'react-router-dom';
import { get } from 'lodash';
import { useEnv } from 'contexts/env';
import routes from 'routes';

const OLD_RULE_PATH = 'regulator.legacyRule';
const NEW_RULE_PATH = 'regulator.rule';

const useRoutes = () => {
  const env = useEnv();

  const rule = env.FF_FORCE_NEW_RULE_PAGE ? get(routes, NEW_RULE_PATH) : get(routes, OLD_RULE_PATH);

  const routesWithConveniences = { ...routes, rule };

  const generateUrl = (dotPath, params) => {
    const pathObject = get(routesWithConveniences, dotPath);

    return pathObject.generateUrl
      ? pathObject.generateUrl(params)
      : routerGenerateUrl(get(routesWithConveniences, `${dotPath}.path`), params);
  };

  return {
    ...routesWithConveniences,
    generateUrl,
  };
};

export default useRoutes;
