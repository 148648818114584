// Libs
import React, { useState } from 'react';

// Mui Components
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

// Local Components
import CreateSectionForm from 'components/Sections/CreateSectionForm';
import RuleUpsertForm from 'components/Rules/RuleUpsertForm';
import Tree from 'components/Tree';
import useRoutes from 'hooks/useRoutes';

const RegulatorTreeView = ({ regulator, ruleId }) => {
  const [nodeToAppendTo, setNodeToAppendTo] = useState();
  const sx = useSx();
  const routes = useRoutes();

  const regulatorId = regulator?.id;

  return (
    <>
      <Tree
        sx={sx.root}
        flex={1}
        data={regulator}
        selectedId={ruleId}
        getChildren={(node) => node.sections?.nodes || node.rules?.nodes}
        getLabel={(node) => node.name}
        getChildrenType={getChildrenType}
        generateUrl={(id) => routes.generateUrl('rule', { regulatorId, ruleId: id })}
        onClickAddNew={setNodeToAppendTo}
        leafTypes={['Rule']}
        editPath={routes.generateUrl('regulator.edit', { regulatorId })}
      />

      {['Module', 'Subject'].includes(getChildrenType(nodeToAppendTo)) && (
        <CreateSectionForm
          onClose={setNodeToAppendTo}
          parent={nodeToAppendTo}
          breadCrumbsLabels={getBreadcrumbsLabels(regulator, nodeToAppendTo)}
        />
      )}
      {['Rule'].includes(getChildrenType(nodeToAppendTo)) && (
        <RuleUpsertForm
          rule={null}
          onClose={setNodeToAppendTo}
          parent={nodeToAppendTo}
          breadCrumbsLabels={getBreadcrumbsLabels(regulator, nodeToAppendTo)}
        />
      )}
    </>
  );
};

const getChildrenType = (node) => {
  switch (node?.__typename) {
    case 'Regulator':
      return 'Module';
    case 'Section':
      return node.relationalType === 'AscentModule' ? 'Subject' : 'Rule';
    default:
      return null;
  }
};

const getBreadcrumbsLabels = (regulator, node) => {
  if (node.id === regulator.id) return [regulator.name];

  for (const module of regulator.sections.nodes) {
    if (node.id === module.id) return [regulator.name, module.name];

    for (const subject of module.sections.nodes) {
      if (node.id === subject.id) return [regulator.name, module.name, subject.name];
    }
  }

  return [];
};

export const RegulatorTreeLoadingView = () => (
  <Box sx={useSx().root}>
    <Skeleton variant="rounded" width={490} height={24} sx={{ mb: 4 }} />

    <Skeleton variant="rounded" height={2} sx={{ mb: 1 }} />

    <Box display="flex" justifyContent="space-between" sx={{ mb: 3 }}>
      <Box display="flex">
        <Skeleton variant="rounded" width={80} height={20} sx={{ mr: 2 }} />
        <Skeleton variant="rounded" width={80} height={20} />
      </Box>
      <Box display="flex">
        <Skeleton variant="rounded" width={50} height={20} />
      </Box>
    </Box>

    <Box display="flex" alignItems="flex-end" flexDirection="column">
      <Skeleton variant="rounded" sx={{ width: '100%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '90%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '90%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '100%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '90%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '90%', height: 20, mb: 1 }} />
      <Skeleton variant="rounded" sx={{ width: '95%', height: 20, mb: 1 }} />
    </Box>
  </Box>
);

const useSx = () => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    padding: 2,
    flexDirection: 'column',
  },
});

export default RegulatorTreeView;
