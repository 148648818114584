import { isDate, formatISO, parseISO } from 'date-fns';

export const parseDate = (date) => (typeof date === 'string' ? parseISO(date) : date);

export const toISODateString = (date) => {
  const parsedDate = parseDate(date);

  if (!isDate(parsedDate) || isNaN(parsedDate)) return null;

  return formatISO(parsedDate, { representation: 'date' });
};

// Takes "2022-01-01" and produces a JS date like:
// Sat Jan 01 2022 00:00:00 GMT-0600 (Central Standard Time)
export const toLocalizedDate = (dateStr) =>
  /\d{4}-\d{2}-\d{2}/.test(dateStr) ? new Date(`${dateStr}T00:00`) : null;
