import { createTheme, alpha } from '@mui/material/styles';
import { Blue, Gray, Green, Red, Yellow } from './colors';

const defaultTheme = createTheme();

export default createTheme({
  // TODO: Fix this palette to work with buttons
  palette: {
    primary: {
      background: '#FFFFFF',
      light: '#FAFAFA',
      main: '#E6E6E6',
      dark: '#878787',
      contrastText: '#333333',
    },
    secondary: {
      light: '#EFF4F9',
      main: '#485D8E',
      contrastText: '#FFFFFF',
    },
    blue: Blue,
    gray: Gray,
    green: Green,
    red: Red,
    yellow: Yellow,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        div.section {
          padding: 5px 30px;
          flex-grow: 1,
        }
      `,
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          marginBottom: defaultTheme.spacing(2),
        },
      },
    },
    // TODO: Delete this override
    MuiButton: {
      styleOverrides: {
        root: {
          border: 'none',
          color: Blue[200],
          padding: '8px 0',
          marginRight: '15px',
          '&:last-child': {
            marginRight: 0,
          },
          '&:hover': {
            border: 'none',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          paddingRight: '5em',
          '&.Mui-selected': {
            backgroundColor: alpha(defaultTheme.palette.primary.main, 0.1),
            '&:hover': {
              backgroundColor: alpha(defaultTheme.palette.primary.main, 0.2),
            },
          },
          '&:hover': {
            backgroundColor: alpha(defaultTheme.palette.primary.main, 0.2),
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginTop: defaultTheme.spacing(2),
          marginBottom: 0,
        },

        flexContainer: {},
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(97, 97, 97, 0.95)',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        borderRight: '1px solid ' + defaultTheme.palette.primary.main,
        borderLeft: '1px solid ' + defaultTheme.palette.primary.main,
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          alignItems: 'center',
        },
        message: {
          padding: '0',
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          margin: '30px 0',
        },
      },
    },
  },
  typography: {
    fontFamily: ['Source Sans Pro', 'sans-serif'].join(','),
    h1: {
      fontWeight: 600,
      fontSize: '2.2em',
      lineHeight: '2.5em',
      letterSpacing: '-0.01px',
    },
    h2: {
      fontWeight: 600,
      fontSize: '2.0em',
      lineHeight: '1.5em',
      letterSpacing: '-0.01px',
      margin: 0,
      padding: 0,
    },
    h3: {
      fontWeight: 600,
      fontSize: '1.5em',
      lineHeight: '1.75em',
      letterSpacing: '-0.01px',
    },
    h6: {
      fontWeight: 600,
      fontSize: '4px',
      lineHeight: '28px',
      letterSpacing: '-0.01px',
    },
    subtitle1: {
      fontWeight: 600,
      fontSize: '1.25em',
      lineHeight: '1.8em',
      letterSpacing: '-0.01px',
    },
    subtitle2: {
      fontWeight: 600,
      fontSize: '1em',
      lineHeight: '1.5em',
      letterSpacing: '-0.01px',
    },
    body1: {
      fontWeight: 400,
      fontSize: '1em',
      lineHeight: '1.4em',
      letterSpacing: '-0.006px',
    },
    body2: {
      fontWeight: 400,
      fontSize: '0.875em',
      lineHeight: '1.3em',
      letterSpacing: '0.1px',
    },
    button: {
      fontWeight: 700,
      fontSize: '0.875em',
      lineHeight: '1em',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
    caption: {
      fontWeight: 400,
      fontSize: '0.75',
      lineHeight: '1em',
      letterSpacing: '1px',
    },
    overline: {
      fontWeight: 600,
      fontSize: '0.875em',
      lineHeight: '1.3em',
      letterSpacing: '1px',
      textTransform: 'uppercase',
    },
  },
});
