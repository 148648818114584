import { useState } from 'react';

const useVersionDelete = () => {
  const [scope, setScope] = useState({ isOpen: false });

  const handleClose = () => {
    setScope({ isOpen: false });
  };

  const deleteVersion = (id, versionsCount) => {
    setScope({ id, versionsCount, isOpen: true });
  };

  const props = {
    handleClose,
    isOpen: scope.isOpen,
    id: scope.id,
    versionsCount: scope.versionsCount,
  };

  return { props, deleteVersion };
};

export default useVersionDelete;
