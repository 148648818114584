import { gql } from '@apollo/client';

export const REGULATOR_TREE_QUERY = gql`
  query Regulator($id: String, $cursor: String) {
    regulator(id: $id) {
      id
      name
      sections(after: $cursor) {
        nodes {
          id
          name
          position
          relationalType
          sections {
            nodes {
              id
              name
              position
              relationalType
              rules {
                nodes {
                  id
                  name
                  position
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const REGULATOR_FULL_QUERY = gql`
  query Regulator($id: String, $cursor: String) {
    regulator(id: $id) {
      id
      name
      sections(after: $cursor) {
        nodes {
          id
          name
          position
          sections {
            nodes {
              id
              name
              position
              rules {
                nodes {
                  id
                  name
                  number
                  title
                  text
                  position
                }
              }
            }
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`;

export const UPDATE_SECTION_MUTATION = gql`
  mutation UpdateSection($id: ID!, $name: String, $position: Int) {
    updateSection(input: { id: $id, name: $name, position: $position }) {
      errors
    }
  }
`;

export const UPDATE_REGULATOR_MUTATION = gql`
  mutation UpdateRegulator($id: ID!, $name: String) {
    updateRegulator(input: { id: $id, name: $name }) {
      errors
    }
  }
`;

export const REGULATORS_QUERY = gql`
  query Regulators($first: Int, $after: String, $prefix: String, $type: String) {
    regulators(first: $first, after: $after, prefix: $prefix, type: $type) {
      nodes {
        id
        name
      }
      pageInfo {
        endCursor
        pageCount
        pageSize
        startCursor
      }
    }
  }
`;
