const routes = {
  regulator: {
    path: '/regulators/:regulatorId',
    edit: {
      path: '/regulators/:regulatorId/edit',
    },
    full: {
      path: '/regulators/:regulatorId/full',
    },
    legacyRule: {
      path: '/regulators/:regulatorId',
      generateUrl: ({ regulatorId, ruleId, params = '' }) =>
        `/regulators/${regulatorId}?ruleId=${ruleId}${params}`,
    },
    rule: {
      path: '/regulators/:regulatorId/rules/:ruleId',
      ruleVersion: {
        path: '/regulators/:regulatorId/rules/:ruleId/rule_versions/:ruleVersionId',
        generateUrl: ({ regulatorId, ruleId, ruleVersionId, params = '' }) =>
          `/regulators/${regulatorId}/rules/${ruleId}/rule_versions/${ruleVersionId}${params}`,
      },
      generateUrl: ({ regulatorId, ruleId, params = '' }) =>
        `/regulators/${regulatorId}/rules/${ruleId}?${params}`,
    },
  },
  regulators: {
    path: '/regulators',
  },
  deepLink: {
    path: '/deep_link/find/:uuid',
  },
};

export default routes;
