// Libs
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';

// Material UI Components
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';

// Local Components
import { default as RegulatorTreeView, RegulatorTreeLoadingView } from './index.view';
import { REGULATOR_TREE_QUERY } from 'components/Regulators/queries';
import useQueryParams from 'hooks/useQuery';

const NotFound = () => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%" marginY={2}>
    <Alert severity="error">Unable to find the selected Regulator</Alert>
  </Box>
);

const RegulatorTree = () => {
  const { regulatorId } = useParams();
  const ruleId = useQueryParams().get('ruleId');

  const response = useQuery(REGULATOR_TREE_QUERY, { variables: { id: regulatorId } });
  const { loading, error, data: { regulator } = {} } = response;

  if (loading) return <RegulatorTreeLoadingView />;
  if (!regulator || error) return <NotFound />;

  return <RegulatorTreeView regulator={regulator} ruleId={ruleId} />;
};

export default RegulatorTree;
