// Libs
import { useState } from 'react';

// Mui Components
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';

// Mui Icons
import ListIcon from '@mui/icons-material/List';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const OPEN_WIDTH = 550;
const HEADER_HEIGHT = 64;
const CLOSED_WIDTH = 44;

const Sidebar = ({ defaultOpen, icon, children, ...restProps }) => {
  const [open, setOpen] = useState(defaultOpen);
  const toggleOpen = () => setOpen(!open);
  const sx = useSx();

  const menuIcon = icon || <ListIcon sx={sx.icon} />;

  return (
    <Drawer open={open} sx={sx.drawer(open)} variant="permanent" {...restProps}>
      <Box sx={sx.iconWrapper}>
        <IconButton data-testid="sidebar-toggle" sx={sx.iconButton} onClick={toggleOpen}>
          {open && <ArrowBackIosNewIcon sx={sx.icon} />}
          {!open && menuIcon}
        </IconButton>
      </Box>
      {open && children}
    </Drawer>
  );
};

const useSx = () => ({
  drawer: (open) => {
    const openStyles = {
      width: OPEN_WIDTH,
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
      top: HEADER_HEIGHT + 1,
    };
    const closedStyles = {
      top: HEADER_HEIGHT + 1,
      width: CLOSED_WIDTH,
      height: `calc(100vh - ${HEADER_HEIGHT}px)`,
    };

    return {
      width: OPEN_WIDTH,
      flexShrink: 0,
      overflowX: 'hidden',
      whiteSpace: 'nowrap',
      backgroundColor: 'primary.light',
      boxSizing: 'border-box',
      ...(open && {
        ...openStyles,
        '& .MuiDrawer-paper': openStyles,
      }),
      ...(!open && {
        ...closedStyles,
        '& .MuiDrawer-paper': closedStyles,
      }),
    };
  },
  iconWrapper: {
    position: 'relative',
  },
  icon: {
    color: 'secondary.main',
    fontSize: '0.8em',
  },
  iconButton: {
    position: 'absolute',
    top: 10,
    right: 1.5,
  },
});

export default Sidebar;
