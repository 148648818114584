import { useCallback } from 'react';
import { useToast } from 'contexts/toast';

const useCopyToClipboard = () => {
  const toastMessage = useToast();

  const copyToClipboard = useCallback(
    (text, message) => {
      navigator.clipboard.writeText(text).then(() => toastMessage('success', message));
    },
    [toastMessage]
  );

  return copyToClipboard;
};

export default useCopyToClipboard;
