import { useState, forwardRef } from 'react';
import PropTypes from 'prop-types';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

const ToastTransition = (props) => <Slide {...props} direction="left" />;

const ToastAlert = forwardRef((props, ref) => (
  <Alert ref={ref} elevation={6} variant="filled" {...props} />
));

const Toast = ({ open, message, severity, sx = {}, setOpen = () => {}, onClose = () => {} }) => {
  const handleClose = (_, reason) => {
    if (reason === 'clickaway') return;

    setOpen(false);
  };

  return (
    <Snackbar
      open={open}
      onClose={handleClose}
      autoHideDuration={6000}
      TransitionComponent={ToastTransition}
      TransitionProps={{ onExited: onClose }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      sx={sx}
      data-testid="alert-popup"
    >
      <ToastAlert severity={severity} onClose={handleClose} sx={{ width: '100%' }}>
        <Typography mt={0.6}>{message}</Typography>
      </ToastAlert>
    </Snackbar>
  );
};

Toast.propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  severity: PropTypes.string,
  sx: PropTypes.object,
  setOpen: PropTypes.func,
  onClose: PropTypes.func,
};

export const ClosableToast = (props) => {
  const [open, setOpen] = useState(true);

  return <Toast {...props} open={open} setOpen={setOpen} />;
};

export default Toast;
