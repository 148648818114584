import { gql } from '@apollo/client';

export const CREATE_SECTION_MUTATION = gql`
  mutation CreateSection(
    $name: String!
    $ancestorType: String
    $ancestorId: String
    $relationalType: String
  ) {
    createSection(
      input: {
        name: $name
        ancestorType: $ancestorType
        ancestorId: $ancestorId
        relationalType: $relationalType
      }
    ) {
      section {
        id
        name
      }
      errors
    }
  }
`;

export const DELETE_SECTION_MUTATION = gql`
  mutation DeleteSection($id: String!) {
    deleteSection(input: { id: $id }) {
      errors
    }
  }
`;
