import { useState } from 'react';
import { truncate } from 'lodash';
import Box from '@mui/material/Box';
import { Gray } from 'components/App/colors';
import TextField from '@mui/material/TextField';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const EditArea = ({ text, variant, onSubmit, onClick = () => {} }) => {
  const sx = useSx();
  const [value, setValue] = useState(text);
  const [isEditing, setIsEditing] = useState(false);

  const handleCancel = () => {
    setValue(text);
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (text !== value) {
      setValue(value);
      onSubmit(value);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSubmit();
    } else if (event.key === 'Escape') {
      handleCancel();
    }
  };

  return (
    <Box sx={sx.root} data-testid="edit-area">
      {isEditing ? (
        <ClickAwayListener onClickAway={handleSubmit}>
          <TextField
            fullWidth
            autoFocus
            size="small"
            value={value}
            onKeyDown={handleKeyDown}
            inputProps={{ 'aria-label': 'edit-input' }}
            onChange={(event) => setValue(event.target.value)}
            sx={sx.input}
          />
        </ClickAwayListener>
      ) : (
        <Typography data-testid="text-view" sx={sx.text} variant={variant} onClick={onClick}>
          {truncate(value, { length: 150 })}
        </Typography>
      )}
      {!isEditing && (
        <EditIcon data-testid="edit-icon" sx={sx.editIcon} onClick={() => setIsEditing(true)} />
      )}
    </Box>
  );
};

const useSx = () => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '1.8em',
    overflow: 'hidden',
  },
  text: {
    flexGrow: 1,
  },
  editIcon: {
    margin: '0.2em 0 0 0',
    cursor: 'pointer',
    color: Gray[500],
    fontSize: '1em',
  },
  input: {
    fontSize: '0.75em',
    padding: 0,

    '& .MuiOutlinedInput-input': {
      backgroundColor: '#fff',
      padding: '5px 10px',
    },
  },
});

export default EditArea;
