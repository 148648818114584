import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';
import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { useToast } from 'contexts/toast';

// Static Assets
import {
  NEO_RULE_QUERY,
  NEO_RULE_VERSION_QUERY,
  RULE_VERSION_APPEARING_PASSAGES_QUERY,
} from '../queries';
import {
  REQUIREMENT_VERSION_UPDATE_MUTATION,
  REQUIREMENT_VERSION_DELETE_MUTATION,
} from 'components/Requirements/queries';
import {
  SUPPORTING_INFORMATION_VERSION_UPDATE_MUTATION,
  SUPPORTING_INFORMATION_VERSION_DELETE_MUTATION,
} from 'components/SupportingInformations/queries';

const refetchQueries = (ruleId, ruleVersionId) => [
  {
    query: NEO_RULE_QUERY,
    variables: { id: ruleId },
  },
  {
    query: NEO_RULE_VERSION_QUERY,
    variables: { id: ruleId },
  },
  {
    query: RULE_VERSION_APPEARING_PASSAGES_QUERY,
    variables: { id: ruleVersionId },
  },
];

export const useRunMutation = () => {
  const toastMessage = useToast();

  const runMutation = async (mutation, type, args) => {
    try {
      const {
        data: {
          [type]: { errors },
        },
      } = await mutation(args);

      if (errors.length) {
        return toastMessage('error', errors[0]);
      }

      toastMessage('success', 'Success');
    } catch (error) {
      toastMessage('error', error.message);
    }
  };

  return runMutation;
};

export const useUpdatePassageVersion = () => {
  const { ruleId, ruleVersionId } = useParams();

  const [updateReqVersion] = useMutation(REQUIREMENT_VERSION_UPDATE_MUTATION);
  const [updateSiVersion] = useMutation(SUPPORTING_INFORMATION_VERSION_UPDATE_MUTATION);

  const runMutation = useRunMutation();

  const passageVersionBody = (passageVersion) => ({
    number: passageVersion?.number || '',
    content: passageVersion?.content || '',
    endsAt: toLocalizedDate(passageVersion?.endsAt),
    startsAt: toLocalizedDate(passageVersion?.startsAt),
    frequency: passageVersion?.frequency || '',
    summary: passageVersion?.summary || '',
    detachOverride: passageVersion?.detachOverride || false,
  });

  const updatePassageVersion = async (passageVersion) => {
    const updatePassageVersionMutation =
      passageVersion.__typename === 'RequirementVersion' ? updateReqVersion : updateSiVersion;

    let submitValues = passageVersionBody(passageVersion);

    runMutation(updatePassageVersionMutation, `update${passageVersion.__typename}`, {
      variables: {
        ...submitValues,
        startsAt: toISODateString(submitValues.startsAt),
        endsAt: toISODateString(submitValues.endsAt),
        id: passageVersion.id,
      },
      refetchQueries: refetchQueries(ruleId, ruleVersionId),
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'rule' });
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
      },
    });
  };

  return updatePassageVersion;
};

export const useDeletePassageVersion = () => {
  const { ruleId, ruleVersionId } = useParams();

  const [deleteReqVersion] = useMutation(REQUIREMENT_VERSION_DELETE_MUTATION);
  const [deleteSiVersion] = useMutation(SUPPORTING_INFORMATION_VERSION_DELETE_MUTATION);

  const runMutation = useRunMutation();

  const deletePassageVersion = async (passageVersion) => {
    const deletePassageMutation =
      passageVersion.__typename === 'RequirementVersion' ? deleteReqVersion : deleteSiVersion;

    runMutation(deletePassageMutation, `delete${passageVersion.__typename}`, {
      variables: {
        allowEntityDeletion: true,
        id: passageVersion.id,
      },
      refetchQueries: refetchQueries(ruleId, ruleVersionId),
      update(cache) {
        cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
      },
    });
  };

  return deletePassageVersion;
};
