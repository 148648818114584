import RuleVersionUpsertFormView from 'components/Rules/RuleVersionUpsertForm/index.view';
import {
  RULE_VERSION_CREATE_MUTATION,
  RULE_VERSION_UPDATE_MUTATION,
} from 'components/Rules/queries';
import { useToast } from 'contexts/toast';
import useForm from 'hooks/useForm';
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';

const buildFormDefaults = (ruleVersion, rule) => ({
  text: (ruleVersion || rule)?.text || '',
  title: (ruleVersion || rule)?.title || '',
  number: (ruleVersion || rule)?.number || '',
  endsAt: toLocalizedDate((ruleVersion || rule)?.endsAt),
  startsAt: toLocalizedDate((ruleVersion || rule)?.startsAt),
  publishedDate: toLocalizedDate((ruleVersion || rule)?.publishedDate),
});

const RuleVersionUpsertForm = ({
  mode,
  rule,
  ruleVersion,
  breadCrumbsLabels,
  onClose,
  refetchQueries,
}) => {
  const toastMessage = useToast();
  const form = useForm(buildFormDefaults(ruleVersion, rule));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isEditting = mode === 'edit';

  const [createRuleVersion] = useMutation(RULE_VERSION_CREATE_MUTATION);
  const [updateRuleVersion] = useMutation(RULE_VERSION_UPDATE_MUTATION);

  const [upsertRuleVersion, entityKey, entityKeyValue, mutationKey] = isEditting
    ? [updateRuleVersion, 'id', ruleVersion.id, 'updateRuleVersion']
    : [createRuleVersion, 'ruleId', rule.id, 'createRuleVersion'];

  const handleSubmit = async () => {
    form.setErrors([]);
    setIsSubmitting(true);

    try {
      const {
        data: {
          [mutationKey]: { errors },
        },
      } = await upsertRuleVersion({
        variables: {
          ...form.values,
          startsAt: toISODateString(form.values.startsAt),
          endsAt: toISODateString(form.values.endsAt),
          publishedDate: toISODateString(form.values.publishedDate),
          [entityKey]: entityKeyValue,
        },
        refetchQueries: refetchQueries,
      });

      setIsSubmitting(false);
      if (errors.length) return form.setErrors(errors);
      onClose();

      toastMessage(
        'success',
        `Rule version has been ${isEditting ? 'updated' : 'created'} successfully`
      );
    } catch (error) {
      form.setErrors([error.message]);
      setIsSubmitting(false);
    }
  };

  const isLastVersion = ruleVersion.nextVersion === null;

  return (
    <RuleVersionUpsertFormView
      breadCrumbsLabels={breadCrumbsLabels}
      form={form}
      isEditing={isEditting}
      isSubmitting={isSubmitting}
      onClose={onClose}
      onSubmit={handleSubmit}
      rule={rule}
      isLastVersion={isLastVersion}
    />
  );
};

export default RuleVersionUpsertForm;
