import { DatePicker } from '@mui/x-date-pickers';
import TextField from '@mui/material/TextField';
import { AicField } from 'components/Forms';
import {
  FormModal,
  FormModalAlert,
  FormModalContent,
  FormModalContentLeft,
  FormModalContentRight,
  FormModalHeader,
  FormModalBreadCrumbs,
} from 'components/Forms/FormModal';

const AIC_MAPPING = [
  { label: 'D', value: 'definition' },
  { label: 'CL', value: 'clarification' },
  { label: 'E', value: 'exemption' },
  { label: 'Z', value: 'catchall' },
  { label: 'G', value: 'government' },
  { label: 'AT', value: 'appendix_text' },
];

export const SupportingInformationCreateFormView = ({
  form,
  isSubmitting,
  handleSubmit,
  breadCrumbsLabels,
  onClose,
  isEditing,
  disableDates,
}) => {
  return (
    <FormModal isOpen={true}>
      <FormModalHeader
        title="New Supporting Information"
        submitBtnText="Save Supporting Information"
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onReset={() => form.reset()}
        onClose={onClose}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      />

      {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <FormModalContentLeft>
          <TextField
            {...form.props.number}
            size="small"
            label="Number"
            value={form.values.number}
          />
          <DatePicker
            {...form.props.startsAt}
            label="Start Date"
            value={form.values.startsAt}
            renderInput={(params) => <TextField {...params} size="small" />}
            disabled={disableDates}
          />
          <DatePicker
            {...form.props.endsAt}
            label="End Date"
            value={form.values.endsAt}
            renderInput={(params) => <TextField {...params} size="small" />}
            disabled={disableDates}
          />
          <AicField {...form.props.aic} aicMapping={AIC_MAPPING} value={form.values.aic} />
        </FormModalContentLeft>
        <FormModalContentRight>
          <TextField
            {...form.props.content}
            fullWidth
            value={form.values.content}
            multiline
            label="Content"
          />
        </FormModalContentRight>
      </FormModalContent>
    </FormModal>
  );
};

export default SupportingInformationCreateFormView;
