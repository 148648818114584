import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import {
  FormModal,
  FormModalContent,
  FormModalContentLeft,
  FormModalContentRight,
  FormModalHeader,
  FormModalAlert,
  FormModalBreadCrumbs,
} from 'components/Forms/FormModal';

const MANUAL_ENRICHMENT_WARNING_MESSAGE =
  'Automatic enrichment tagging has been disabled. This Rule Version will not be automatically enriched.';

export const RuleVersionUpsertFormView = ({
  breadCrumbsLabels,
  form,
  isEditing,
  isSubmitting,
  onClose,
  onSubmit,
  rule,
  isLastVersion,
}) => {
  const sx = useSx();

  return (
    <FormModal isOpen={true}>
      <FormModalHeader
        title={`${isEditing ? 'Edit' : 'New'} Rule Version`}
        submitBtnText="Save Rule Version"
        isSubmitting={isSubmitting}
        onSubmit={onSubmit}
        onReset={() => form.reset()}
        onClose={onClose}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      ></FormModalHeader>
      {!!form.errors.length && <FormModalAlert severity="error" messages={form.errors} />}
      {rule.enrichmentFlow === 'manual' && (
        <FormModalAlert severity="warning" messages={[MANUAL_ENRICHMENT_WARNING_MESSAGE]} />
      )}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <FormModalContentLeft>
          <TextField
            {...form.props.number}
            size="small"
            label="Rule Number"
            value={form.values.number}
            sx={sx.field}
          />
          <TextField
            {...form.props.title}
            size="small"
            label="Rule Title"
            value={form.values.title}
            sx={sx.field}
          />
          <DatePicker
            {...form.props.startsAt}
            label="Start Date"
            value={form.values.startsAt}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
          <DatePicker
            {...form.props.endsAt}
            label="End Date"
            value={form.values.endsAt}
            disabled={!isLastVersion}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
          <DatePicker
            {...form.props.publishedDate}
            label="Published Date"
            value={form.values.publishedDate}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
        </FormModalContentLeft>
        <FormModalContentRight>
          <TextField
            {...form.props.text}
            value={form.values.text}
            multiline
            label="Text"
            sx={sx.field}
          />
        </FormModalContentRight>
      </FormModalContent>
    </FormModal>
  );
};

const useSx = () => ({
  field: {
    mt: 0.8,
    mb: 2,
    width: '100%',
  },
});

export default RuleVersionUpsertFormView;
