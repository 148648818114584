import React, { createContext, useContext, useEffect, useState } from 'react';

export const EnvContext = createContext({
  env: null,
});

export const EnvProvider = ({ children }) => {
  const [env, setEnv] = useState(null);

  useEffect(
    () =>
      !env &&
      fetch('/env')
        .then((res) => res.json())
        .then((envResp) => setEnv(envResp)),
    [env]
  );

  if (!env) return null;

  return <EnvContext.Provider value={env}>{children}</EnvContext.Provider>;
};

export const MockedEnvProvider = ({ env, children }) => (
  <EnvContext.Provider value={env}>{children}</EnvContext.Provider>
);

export const useEnv = () => useContext(EnvContext);
