import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import RegulatorView from './index.view';
import { REGULATOR_FULL_QUERY } from 'components/Regulators/queries';

const RegulatorFull = () => {
  const { regulatorId } = useParams();

  const {
    loading,
    error,
    data: { regulator } = {},
  } = useQuery(REGULATOR_FULL_QUERY, { variables: { id: regulatorId } });

  const props = { loading, error, regulator };

  return <RegulatorView {...props} />;
};

export default RegulatorFull;
