import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';

import { useEnv } from '../env';

const onRedirectCallback = (appState) => {
  window.history.replaceState({}, document.title, appState.targetUrl);
};

export const AuthProvider = ({ children }) => {
  const env = useEnv();

  return (
    <Auth0Provider
      domain={env.AUTH0_DOMAIN}
      clientId={env.AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={env.AUTH0_AUDIENCE}
      onRedirectCallback={onRedirectCallback}
      scope="modify:*"
    >
      {children}
    </Auth0Provider>
  );
};
