import { isEmpty } from 'lodash';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { Blue, Gray } from 'components/App/colors';
import RuleChildrenRow from 'components/Rules/RuleChildrenTable/RuleChildrenRow';

const RuleChildrenTableView = ({ rows, createSi, createReq, onRowDragEnd, isChildrenMutable }) => {
  const sx = useSx();

  return (
    <>
      <Box sx={sx.heading}>
        <Typography variant="subtitle2">Requirements &amp; Supporting Information</Typography>
        {isChildrenMutable && (
          <Box display="flex" alignItems="center">
            <Button aria-label="Add Requirement" startIcon={<AddCircleIcon />} onClick={createReq}>
              Add Requirement
            </Button>
            <Button
              aria-label="Add Supporting information"
              startIcon={<AddCircleIcon />}
              onClick={createSi}
            >
              Add Supporting Information
            </Button>
          </Box>
        )}
      </Box>
      <TableContainer>
        <Table aria-label="data table">
          <TableHead sx={{ backgroundColor: Gray[200] }}>
            <TableRow>
              <TableCell sx={sx.dataCell}></TableCell>
              <TableCell sx={sx.dataCell}>
                <Typography variant="overline">TYPE</Typography>
              </TableCell>
              <TableCell sx={sx.dataCell}>
                <Typography variant="overline">NUMBER</Typography>
              </TableCell>
              <TableCell>
                <Typography variant="overline">CONTENT</Typography>
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="overline">START DATE</Typography>
              </TableCell>
              <TableCell sx={{ whiteSpace: 'nowrap' }}>
                <Typography variant="overline">END DATE</Typography>
              </TableCell>
              <TableCell align="center" colSpan={4}>
                <Typography variant="overline">ACTIONS</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={onRowDragEnd}>
            <Droppable droppableId="rule-children-table">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {isEmpty(rows) ? (
                    <TableRow>
                      <TableCell colSpan={7} sx={{ textAlign: 'center' }}>
                        <Typography>No Results</Typography>
                      </TableCell>
                    </TableRow>
                  ) : (
                    rows.map((row, index) => (
                      <RuleChildrenRow
                        {...row}
                        key={row.id}
                        index={index}
                        isMutable={isChildrenMutable}
                      />
                    ))
                  )}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
        </Table>
      </TableContainer>
    </>
  );
};

const useSx = () => ({
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: (theme) => theme.palette.primary.background,
  },
  dataCell: {
    whiteSpace: 'nowrap',
  },
  ruleNumberCell: {
    padding: '16px 0',
  },
  link: {
    textDecoration: 'none',
    color: Blue[200],
    cursor: 'pointer',
  },
  icon: {
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: Blue[50],
      color: Blue[200],
    },
  },
  heading: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1em',
    alignItems: 'center',
  },
  iconCell: {
    padding: '0 5px',
    width: '1em',
  },
});

export default RuleChildrenTableView;
