import { Link as RouterLink } from 'react-router-dom';
import { Gray } from 'components/App/colors';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Pagination from '@mui/material/Pagination';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { AlphaTabFilter } from 'components/Forms';
import useRoutes from 'hooks/useRoutes';

const RegulatorsView = ({ regulators, page, pageCount, setPage, tabsOnChange, currentTab }) => {
  const sx = useSx();
  const routes = useRoutes();

  const onPageClick = (_event, page) => {
    window.scrollTo(0, 0);
    setPage(page);
  };

  return (
    <Box px={4}>
      <Typography variant="h1" component="h1" data-testid="page-header">
        Regulators
      </Typography>
      <Typography variant="subtitle2">Browse Regulators Alphabetically</Typography>
      <AlphaTabFilter onChange={tabsOnChange} currentTab={currentTab} />

      {pageCount > 1 && (
        <Box display="flex" justifyContent="center">
          <Pagination
            data-testid="pagination-controls"
            count={pageCount}
            onChange={onPageClick}
            page={page}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
      <Grid container spacing={1} my={2}>
        {regulators.map(({ id: regulatorId, name }) => {
          return (
            <Grid item sm={12} md={6} sx={sx.gridItem} key={regulatorId}>
              <Link
                component={RouterLink}
                data-testid={regulatorId}
                to={routes.generateUrl('regulator', { regulatorId })}
                sx={sx.link}
              >
                <Typography sx={{ fontSize: '0.95em' }} variant="body2">
                  {name}
                </Typography>
              </Link>
            </Grid>
          );
        })}
      </Grid>
      {regulators.length === 0 && <Box sx={sx.noRegulator}>No Regulators Found</Box>}
      {pageCount > 1 && (
        <Box display="flex" justifyContent="center">
          <Pagination
            data-testid="pagination-controls"
            count={pageCount}
            onChange={onPageClick}
            page={page}
            showFirstButton
            showLastButton
          />
        </Box>
      )}
    </Box>
  );
};

const useSx = () => ({
  link: {
    textDecoration: 'none',
    lineHeight: '1em',
    color: Gray[700],
  },
  noRegulator: {
    padding: '45px 25px',
    textAlign: 'center',
  },
  gridItem: {
    padding: 0.75,
    '&:hover': {
      backgroundColor: 'secondary.light',
    },
  },
});

RegulatorsView.propTypes = {
  regulators: PropTypes.arrayOf(
    PropTypes.shape({
      regulator: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ),
};

export default RegulatorsView;
