import { gql } from '@apollo/client';

export const NEO_RULE_QUERY = gql`
  query Rule($id: String) {
    rule(id: $id) {
      id
      defectCheckStatus
      name
      number
      regulatorId
      text
      title
      defects {
        nodes {
          id
          defectType
          title
          description
          metadata
        }
      }
      answers {
        id
        content
        question {
          id
        }
      }
      versions {
        nodes {
          id
          startsAt
          endsAt
          enrichmentStatus
          nextVersion {
            id
          }
          defects {
            pageInfo {
              totalCount
            }
          }
        }
      }
      detachedPassageVersions {
        nodes {
          ... on RequirementVersion {
            __typename
            id
            aic
            number
            startsAt
            frequency
            endsAt
            summary
            content: taskContent
            passage {
              id
              position
              versions {
                pageInfo {
                  totalCount
                }
              }
            }
          }
          ... on SupportingInformationVersion {
            __typename
            id
            aic
            number
            startsAt
            endsAt
            content
            passage {
              id
              position
              versions {
                pageInfo {
                  totalCount
                }
              }
            }
          }
        }
      }
      ancestors {
        __typename
        ... on Regulator {
          name
        }
        ... on Section {
          name
        }
      }
      regulator {
        id
        name
      }
      currentVersion {
        id
      }
      enrichmentFlow
    }
  }
`;

export const NEO_RULE_VERSION_QUERY = gql`
  query RuleVersion($id: String) {
    ruleVersion(id: $id) {
      id
      number
      text
      title
      startsAt
      endsAt
      publishedDate
      enrichmentStatus
      defects {
        nodes {
          id
          defectType
          title
          description
          metadata
        }
      }
      nextVersion {
        id
      }
    }
  }
`;

export const RULE_VERSION_APPEARING_PASSAGES_QUERY = gql`
  query RuleVersion($id: String) {
    ruleVersion(id: $id) {
      id
      appearingPassageVersions {
        nodes {
          ... on RequirementVersion {
            __typename
            id
            aic
            number
            frequency
            startsAt
            endsAt
            summary
            content: taskContent
            defects {
              nodes {
                id
                defectType
                title
                description
                metadata
              }
            }
            passage {
              __typename
              id
              position
              versions {
                pageInfo {
                  totalCount
                }
              }
            }
            ruleVersion {
              id
              startsAt
            }
            previousVersion {
              id
            }
            nextVersion {
              id
            }
          }
          ... on SupportingInformationVersion {
            __typename
            id
            aic
            number
            content
            startsAt
            endsAt
            defects {
              nodes {
                id
                defectType
                title
                description
                metadata
              }
            }
            passage {
              __typename
              id
              position
              versions {
                pageInfo {
                  totalCount
                }
              }
            }
            ruleVersion {
              id
              startsAt
            }
            previousVersion {
              id
            }
            nextVersion {
              id
            }
          }
        }
      }
    }
  }
`;

export const RULE_QUERY = gql`
  query Rule($id: String) {
    rule(id: $id) {
      id
      number
      text
      title
      name
      versions {
        nodes {
          id
          startsAt
          endsAt
          enrichmentStatus
          nextVersion {
            id
          }
        }
      }
      answers {
        id
        content
        question {
          id
        }
      }
      ancestors {
        __typename
        ... on Regulator {
          name
        }
        ... on Section {
          name
        }
      }
      regulator {
        id
        name
      }
      currentVersion {
        id
        startsAt
        endsAt
        number
        text
        title
      }
      enrichmentFlow
      defectCheckStatus
      hasInvalidChildren
      hasInvalidStartsAtChildren
      hasInvalidEndsAtChildren
      hasInvalidPositionChildren
    }
  }
`;

export const UPDATE_RULE_CHILDREN_MUTATION = gql`
  mutation UpdateRuleChildren($childType: String, $id: String, $position: Int) {
    updateRuleChildren(input: { childType: $childType, id: $id, position: $position }) {
      errors
    }
  }
`;

export const RULE_VERSION_QUERY = gql`
  query RuleVersion($id: String) {
    ruleVersion(id: $id) {
      id
      number
      text
      title
      startsAt
      endsAt
      publishedDate
      enrichmentStatus
      nextVersion {
        id
      }
    }
  }
`;

export const RULE_CHILDREN_QUERY = gql`
  query RuleChildren($id: String, $startsAt: ISO8601Date!) {
    rule(id: $id) {
      id
      children {
        nodes {
          ... on Requirement {
            __typename
            id
            position
            versions {
              pageInfo {
                totalCount
              }
            }
            answers {
              id
              content
              question {
                id
              }
            }
            version: currentVersionFor(startsAt: $startsAt) {
              id
              aic
              number
              frequency
              startsAt
              endsAt
              summary
              content: taskContent
              effectiveStatus: effectiveStatusFor(startsAt: $startsAt)
            }
          }
          ... on SupportingInformation {
            __typename
            id
            position
            versions {
              pageInfo {
                totalCount
              }
            }
            version: currentVersionFor(startsAt: $startsAt) {
              id
              aic
              number
              content
              startsAt
              endsAt
              effectiveStatus: effectiveStatusFor(startsAt: $startsAt)
            }
          }
        }
      }
    }
  }
`;

export const CREATE_RULE_MUTATION = gql`
  mutation CreateRule(
    $answerIds: [String!]
    $endsAt: ISO8601Date
    $number: String!
    $publishedDate: ISO8601Date!
    $startsAt: ISO8601Date!
    $text: String!
    $title: String
    $subjectId: String!
    $newAnswers: [String!]
  ) {
    createRule(
      input: {
        answerIds: $answerIds
        endsAt: $endsAt
        newAnswers: $newAnswers
        number: $number
        publishedDate: $publishedDate
        startsAt: $startsAt
        subjectId: $subjectId
        text: $text
        title: $title
      }
    ) {
      rule {
        id
        name
      }
      errors
    }
  }
`;

export const UPDATE_RULE_MUTATION = gql`
  mutation UpdateRule(
    $id: String!
    $endsAt: ISO8601Date
    $enrichmentFlow: String
    $number: String
    $position: Int
    $publishedDate: ISO8601Date
    $startsAt: ISO8601Date
    $text: String
    $title: String
    $newAnswers: [String!]
    $answerIds: [String!]
    $invalidEndsAtChildrenStatus: String
    $invalidPositionChildrenStatus: String
  ) {
    updateRule(
      input: {
        id: $id
        endsAt: $endsAt
        enrichmentFlow: $enrichmentFlow
        number: $number
        position: $position
        publishedDate: $publishedDate
        startsAt: $startsAt
        text: $text
        title: $title
        newAnswers: $newAnswers
        answerIds: $answerIds
        invalidEndsAtChildrenStatus: $invalidEndsAtChildrenStatus
        invalidPositionChildrenStatus: $invalidPositionChildrenStatus
      }
    ) {
      rule {
        id
        name
        answers {
          id
          content
        }
      }
      errors
    }
  }
`;

export const DELETE_RULE_MUTATION = gql`
  mutation DeleteRule($id: String!) {
    deleteRule(input: { id: $id }) {
      errors
    }
  }
`;

export const RULE_VERSION_CREATE_MUTATION = gql`
  mutation CreateRuleVersion(
    $text: String!
    $title: String
    $number: String!
    $ruleId: ID!
    $endsAt: ISO8601Date
    $startsAt: ISO8601Date!
    $publishedDate: ISO8601Date!
  ) {
    createRuleVersion(
      input: {
        text: $text
        title: $title
        number: $number
        ruleId: $ruleId
        endsAt: $endsAt
        startsAt: $startsAt
        publishedDate: $publishedDate
      }
    ) {
      ruleVersion {
        id
        text
        title
        number
        startsAt
        endsAt
        publishedDate
      }
      errors
    }
  }
`;

export const RULE_VERSION_UPDATE_MUTATION = gql`
  mutation UpdateRuleVersion(
    $id: ID!
    $text: String
    $title: String
    $number: String!
    $startsAt: ISO8601Date!
    $endsAt: ISO8601Date
    $publishedDate: ISO8601Date!
  ) {
    updateRuleVersion(
      input: {
        id: $id
        text: $text
        title: $title
        number: $number
        startsAt: $startsAt
        endsAt: $endsAt
        publishedDate: $publishedDate
      }
    ) {
      ruleVersion {
        id
        text
        title
        number
        startsAt
        endsAt
        publishedDate
      }
      errors
    }
  }
`;

export const DELETE_RULE_VERSION_MUTATION = gql`
  mutation DeleteRuleVersion(
    $id: String!
    $allowEntityDeletion: Boolean!
    $detachOnDestroy: Boolean!
  ) {
    deleteRuleVersion(
      input: {
        id: $id
        allowEntityDeletion: $allowEntityDeletion
        detachOnDestroy: $detachOnDestroy
      }
    ) {
      errors
    }
  }
`;

export const RULE_DETACHED_PASSAGES_VERSION_QUERY = gql`
  query Rule($id: String) {
    rule(id: $id) {
      id
      detachedPassageVersions {
        nodes {
          ... on RequirementVersion {
            id
            baseContent
            taskContent
            startsAt
            frequency
            aic
            passage: requirement {
              id
            }
          }
          ... on SupportingInformationVersion {
            id
            startsAt
            baseContent
            content
            aic
            passage: supportingInformation {
              id
            }
          }
        }
      }
    }
  }
`;
