import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Blue } from 'components/App/colors';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';

const AicField = ({ value, onChange, aicMapping, ...restProps }) => {
  const sx = useSx();

  // eslint-disable-next-line
  const initValue = useMemo(() => value, []);

  return (
    <Box {...restProps}>
      <Typography variant="bold" component="h4" sx={sx.root}>
        Ascent Indicator Code
        <IconButton onClick={() => onChange(initValue)}>
          <SettingsBackupRestoreIcon sx={{ fontSize: '1.2rem' }} />
        </IconButton>
      </Typography>

      <Box>
        {aicMapping.map((aic) => (
          <Tooltip key={aic.label} title={aic.value} placement="top">
            <Button
              variant="outlined"
              size="small"
              sx={sx.aicBtn(value === aic.value)}
              onClick={() => onChange(aic.value)}
            >
              {aic.label}
            </Button>
          </Tooltip>
        ))}
      </Box>
    </Box>
  );
};

const useSx = () => ({
  root: {
    mb: 1,
    display: 'flex',
    alignItems: 'center',
  },
  aicBtn: (active = false) => ({
    color: 'primary.contrastText',
    borderColor: 'primary.main',
    padding: '1em 3em',
    margin: '0.8em 0.5em 0 0',
    ...(!active
      ? {}
      : {
          backgroundColor: Blue[50],
          '&:hover': {
            backgroundColor: Blue[50],
          },
        }),
  }),
});

export default AicField;
