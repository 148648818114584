import { useEffect, useState } from 'react';
import { range } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Box from '@mui/material/Box';
import TreeView from '@mui/lab/TreeView';
import Button from '@mui/material/Button';
import Skeleton from '@mui/material/Skeleton';
import Backdrop from '@mui/material/Backdrop';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CircularProgress from '@mui/material/CircularProgress';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';

import { Blue, Gray } from 'components/App/colors';
import RuleUpsertForm from 'components/Rules/RuleUpsertForm';
import CreateSectionForm from 'components/Sections/CreateSectionForm';
import EditableTreeItem from 'components/Regulators/EditableTreeItem';
import EditArea from 'components/Regulators/EditableTreeItem/EditArea';
import useRoutes from 'hooks/useRoutes';

const reorder = (items, startIndex, endIndex) => {
  const result = Array.from(items);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const RuleBookEditLoader = () => (
  <Box p="2em 2.25em" data-testid="loading">
    <Skeleton height={40} width="5em" sx={{ marginBottom: '.75em' }} />
    <Skeleton height={30} width="50%" sx={{ marginBottom: '.5em' }} />
    {range(0, 15).map((i) => (
      <Box display="flex" alignsections="center" key={i} sx={{ marginBottom: '0.5em' }}>
        <Box display="flex" alignsections="center" flex={4}>
          <Skeleton variant="circular" width={20} height={20} sx={{ mr: '15px' }} />
          <Skeleton variant="rectangular" width={13} height={20} sx={{ mr: '15px' }} />
          <Skeleton width="70%" />
        </Box>
        <Skeleton variant="rectangular" width={13} height={20} sx={{ mr: '15px' }} />
        <Skeleton variant="rectangular" width={13} height={20} sx={{ mr: '15px' }} />
      </Box>
    ))}
  </Box>
);

const NodeView = ({
  type,
  node,
  index,
  deleteNode,
  onEntityCreate,
  updateNodeText,
  updateNodePosition,
}) => {
  const sx = useSx();
  const isSection = ['AscentModule', 'Subject'].includes(type);
  const childrenType = type === 'AscentModule' ? 'Subject' : 'Rule';

  const childNodes = () => {
    if (type === 'Rule') {
      return [];
    } else {
      return (type === 'AscentModule' ? node?.sections?.nodes : node?.rules?.nodes) || [];
    }
  };

  return (
    <EditableTreeItem
      type={type}
      index={index}
      nodeId={node.id}
      nodeText={node.name}
      isSection={isSection}
      deleteNode={deleteNode}
      data-position={node.position}
      updateNodeText={updateNodeText}
    >
      <NodesView
        type={childrenType}
        nodes={childNodes}
        deleteNode={deleteNode}
        onEntityCreate={onEntityCreate}
        updateNodeText={updateNodeText}
        updateNodePosition={updateNodePosition}
      />
      {isSection && (
        <Button
          sx={sx.addNewBtn}
          aria-label="add new"
          onClick={() =>
            onEntityCreate(childrenType === 'Rule' ? childrenType : 'Section', type, node.id)
          }
        >
          Add New {childrenType}
        </Button>
      )}
    </EditableTreeItem>
  );
};

const NodesView = ({
  type,
  nodes,
  deleteNode,
  onEntityCreate,
  updateNodeText,
  updateNodePosition,
}) => {
  const [mutableNodes, setMutableNodes] = useState(nodes);

  useEffect(() => {
    setMutableNodes(nodes);
  }, [nodes]);

  const onDragEnd = ({ type, source, destination, draggableId }) => {
    if (!destination || source.index === destination.index) return;

    const destinationNode = mutableNodes[destination.index];

    setMutableNodes(reorder(mutableNodes, source.index, destination.index));

    updateNodePosition(draggableId, type, destinationNode.position);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable type={type} droppableId={type}>
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {mutableNodes.map((node, index) => (
              <NodeView
                key={node.id}
                type={type}
                node={node}
                index={index}
                deleteNode={deleteNode}
                onEntityCreate={onEntityCreate}
                updateNodeText={updateNodeText}
                updateNodePosition={updateNodePosition}
              />
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const RuleBookEdit = ({
  regulator,
  isLoading,
  deleteNode,
  updateNodeText,
  updateNodePosition,
  getSectionHierarchy,
  questions,
}) => {
  const sx = useSx();
  const routes = useRoutes();
  const navigate = useNavigate();
  const [breadcrumbsLabels, setBreadcrumbslabels] = useState([]);
  const [expanded, setExpanded] = useState([]);
  const [formProps, setFormProps] = useState({ type: null });
  const [parent, setParent] = useState({ id: null, type: null });

  const regulatorId = regulator.id;

  let sectionIds = regulator?.sections?.nodes
    .map(function (module) {
      return [
        ...(module?.sections?.nodes || []).map(function (subject) {
          return subject?.id;
        }),
        module.id,
      ];
    })
    .flat();

  const handleToggle = (_event, nodeIds) => {
    setExpanded(nodeIds);
  };

  const handleExpandAllClick = () => {
    setExpanded(sectionIds);
  };

  const handleContractAllClick = () => {
    setExpanded([]);
  };

  const clearFormProps = () => setFormProps({ type: null });
  const onClose = () => {
    clearFormProps();
    setParent({ id: null, type: null });
    setBreadcrumbslabels([]);
  };

  const createEntity = (type, parentType, parentId) => {
    setFormProps({ type });
    setParent({ id: parentId, type: parentType });
    setBreadcrumbslabels(getSectionHierarchy(parentId, parentType));
  };

  return (
    <>
      <Backdrop open={isLoading} sx={sx.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={sx.root}>
        <Box sx={sx.headerBox}>
          <Button
            aria-label="go back"
            onClick={() => navigate(routes.generateUrl('regulator', { regulatorId }))}
            startIcon={<ArrowBackIcon fontSize="small" />}
          >
            Back
          </Button>
          <Box>
            <Button onClick={handleExpandAllClick} startIcon={<UnfoldMoreIcon fontSize="small" />}>
              Expand all
            </Button>
            <Button
              onClick={handleContractAllClick}
              startIcon={<UnfoldLessIcon fontSize="small" />}
            >
              Collapse all
            </Button>
          </Box>
        </Box>
        <Box sx={sx.regulator}>
          <EditArea
            variant="subtitle2"
            text={regulator.name}
            onSubmit={(value) => updateNodeText(regulator.id, 'Regulator', value)}
          />
        </Box>
        <TreeView
          aria-label="rulebook treeview"
          expanded={expanded}
          onNodeToggle={handleToggle}
          sx={sx.treeView}
        >
          <NodesView
            type="AscentModule"
            nodes={regulator.sections.nodes}
            deleteNode={deleteNode}
            onEntityCreate={createEntity}
            updateNodeText={updateNodeText}
            updateNodePosition={updateNodePosition}
          />
          <Button
            sx={sx.addNewBtn}
            aria-label="add new module"
            onClick={() => createEntity('Section', 'Regulator', regulator?.id)}
          >
            Add New Module
          </Button>
        </TreeView>
        {formProps.type === 'Rule' && (
          <RuleUpsertForm
            rule={null}
            onClose={onClose}
            parent={parent}
            breadCrumbsLabels={breadcrumbsLabels}
            questions={questions}
          />
        )}

        {formProps.type === 'Section' && (
          <CreateSectionForm
            onClose={onClose}
            parent={parent}
            breadCrumbsLabels={breadcrumbsLabels || [regulator?.name]}
          />
        )}
      </Box>
    </>
  );
};

const useSx = () => ({
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: (theme) => theme.palette.primary.background,
  },
  root: {
    padding: '2em',
  },
  headerBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1.8em',
  },
  regulator: {
    width: '98%',
  },
  treeView: {
    flexGrow: 1,
  },
  addNewBtn: {
    '&&': {
      border: 'none',
      color: Blue[200],
      fontSize: '0.8em',
      '&:hover': {
        border: 'none',
      },
      padding: '8px',
    },
    marginLeft: '2.5em',
  },
  icon: {
    color: Gray[500],
    fontSize: '1em',
    mr: 1,
  },
});

export default RuleBookEdit;
