// Libs
import React from 'react';
import { Outlet, useParams } from 'react-router-dom';

// Mui Components
import Box from '@mui/material/Box';

// Mui Icons
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';

// Local Components
import LegacyRuleRedirect from 'components/Rules/LegacyRuleRedirect';
import RegulatorTree from 'components/Regulators/RegulatorTree';
import Sidebar from 'components/Sidebar';
import useQueryParams from 'hooks/useQuery';

const Regulator = () => {
  const ruleIdParam = useParams().ruleId;
  const ruleIdQueryParam = useQueryParams().get('ruleId');
  const ruleId = ruleIdParam || ruleIdQueryParam;

  const sx = useSx();

  return (
    <Box sx={sx.root}>
      <Sidebar
        defaultOpen={!ruleId}
        data-testid="regulator-tree-sidebar"
        icon={<AccountTreeOutlinedIcon />}
      >
        <RegulatorTree />
      </Sidebar>
      <LegacyRuleRedirect />
      <Outlet />
    </Box>
  );
};

const useSx = () => ({
  root: {
    display: 'flex',
  },
});

export default Regulator;
