import React, { createContext, useContext, useEffect, useState } from 'react';
import Rollbar from 'rollbar';
import { Provider, ErrorBoundary as RollbarErrorBoundary } from '@rollbar/react';
import { useEnv } from '../env';

export const RollbarContext = createContext({
  rollbar: null,
});

const createRollbar = (env) => {
  if (!env || env.ASCENT_ENV === 'local') return null;

  return new Rollbar({
    accessToken: env.ROLLBAR_CLIENT_TOKEN,
    environment: env.ROLLBAR_ENV,
  });
};

const emptyRollbar = {
  critical: console.log,
  debug: console.log,
  error: console.log,
  info: console.log,
  log: console.log,
  warning: console.log,
};

export const RollbarProvider = ({ children }) => {
  const env = useEnv();
  const [rollbar, setRollbar] = useState(createRollbar(env));

  useEffect(() => !rollbar && setRollbar(createRollbar(env)), [env, rollbar, setRollbar]);

  // TODO: don't use Mocked* in the main component
  if (!rollbar) return <MockedRollbarProvider rollbar={emptyRollbar} children={children} />;

  return (
    <RollbarContext.Provider value={{ rollbar }}>
      <Provider instance={rollbar}>
        <RollbarErrorBoundary>{children}</RollbarErrorBoundary>
      </Provider>
    </RollbarContext.Provider>
  );
};

export const MockedRollbarProvider = ({ rollbar, children }) => (
  <RollbarContext.Provider value={{ rollbar }}>{children}</RollbarContext.Provider>
);

export const useRollbar = () => useContext(RollbarContext);
