import { useState } from 'react';
import { compact, flatten } from 'lodash';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';

const QuestionField = ({ name, question, onAnswerChange, defaultOptions = [] }) => {
  const theme = createTheme({ palette: { primary: { main: '#878787' } } });

  const defaultValues = defaultOptions.filter((option) => option.question.id === question.id);
  const [selectedOptions, setSelectedOptions] = useState(defaultValues);

  const onChange = (newValue) => {
    const fieldData = newValue.map((value) => {
      if (value?.inputValue) {
        value.id = null;
        value.content = value.inputValue;
        value.questionId = question.id;
        delete value.inputValue;
      }

      return value;
    });

    setSelectedOptions(fieldData);
    onAnswerChange(fieldData, question);
  };

  const filterOptions = (options, params) => {
    const filter = createFilterOptions();
    const filtered = filter(options, params);
    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option.content);

    if (inputValue !== '' && !isExisting) {
      filtered.push({ inputValue, content: `Add "${inputValue}" to question` });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    if (typeof option === 'string') return option;
    if (option.inputValue) return option.inputValue;

    return option.content;
  };

  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        multiple
        disableCloseOnSelect
        freeSolo
        forcePopupIcon
        data-testid={name}
        value={selectedOptions}
        onChange={(_event, newValue) => onChange(newValue)}
        filterOptions={(options, params) => filterOptions(options, params)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={question.answers}
        getOptionLabel={(option) => getOptionLabel(option)}
        renderOption={(props, option) => <li {...props}>{option.content}</li>}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        sx={{ width: '100%' }}
        size="small"
        renderInput={(params) => (
          <TextField
            {...params}
            data-testid={`${name}-input`}
            label={question.content}
            size="small"
            placeholder="Select Answer"
          />
        )}
      />
    </ThemeProvider>
  );
};

const QuestionsFieldSet = ({ questions = [], formProps, defaultOptions = [] }) => {
  const [questionAnswerMap, setQuestionAnswerMap] = useState(
    questions.reduce((result, question) => {
      return {
        ...result,
        [question.id]: defaultOptions.filter((option) => option.question.id === question.id) || [],
      };
    }, {})
  );

  const onAnswerChange = (newAnswersData, question) => {
    const newQuestionAnswerMap = { ...questionAnswerMap, [question.id]: newAnswersData };

    setQuestionAnswerMap(newQuestionAnswerMap);

    const answerIds = compact(
      compact(flatten(Object.values(newQuestionAnswerMap))).map((answer) => answer.id)
    );

    const newAnswers = compact(
      flatten(Object.values(newQuestionAnswerMap)).map((answer) => {
        if (answer.questionId) {
          return JSON.stringify({ questionId: answer.questionId, content: answer.content });
        }
      })
    );

    formProps.newAnswers.onChange(newAnswers);
    formProps.answerIds.onChange(compact(answerIds));
  };

  return (
    <>
      <Typography variant="bold" component="h4">
        Questions &amp; Answers
      </Typography>
      {questions.map((question, index) => (
        <FormControl fullWidth size="small" key={question.id}>
          <QuestionField
            question={question}
            name={`answer${index}`}
            formProps={formProps}
            onAnswerChange={onAnswerChange}
            defaultOptions={defaultOptions}
          />
        </FormControl>
      ))}
    </>
  );
};

export default QuestionsFieldSet;
