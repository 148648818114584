// Libs
import { map, startCase } from 'lodash';

// MUI Components
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import FeedbackIcon from '@mui/icons-material/Feedback';
import { Orange } from 'components/App/colors';

const DefectTitle = ({ defects }) => {
  const sx = useSx();

  return (
    <Box sx={sx.defects}>
      {defects.nodes.map((defect) => (
        <Box key={defect.id} sx={sx.defect}>
          <Typography data-testid={`${defect.id}-title`} sx={sx.defectTitle}>
            {defect.title}
          </Typography>
          <Typography data-testid={`${defect.id}-description`} sx={sx.defectDesc}>
            {defect.description}
          </Typography>
          {defect.metadata?.properties && (
            <Box component="ul" data-testid={`${defect.id}-metadata`} sx={sx.defectMetadata}>
              {defect.metadata.properties.map((field) => (
                <li key={field}>{startCase(field)}</li>
              ))}
            </Box>
          )}
        </Box>
      ))}
    </Box>
  );
};

const DefectToolTip = ({ defects }) => {
  const sx = useSx();

  if (!defects) return null;

  return (
    <Tooltip
      data-testid={map(defects.nodes, 'id').join('-')}
      title={<DefectTitle defects={defects} />}
    >
      <FeedbackIcon sx={sx.icon} />
    </Tooltip>
  );
};

const useSx = () => {
  return {
    defects: {
      paddingX: 2,
      paddingY: 1,
    },
    defect: {
      padding: '1em 0',
      borderBottom: '1px solid white',
      '&:first-of-type': {
        paddingTop: 0,
      },
      '&:last-child': {
        paddingBottom: 0,
        borderBottom: 0,
      },
    },
    defectTitle: {
      fontWeight: 'bold',
      fontSize: '1.4em',
      marginBottom: '0.5em',
    },
    defectDesc: {
      fontSize: '1.2em',
      marginBottom: '0.5em',
    },
    defectMetadata: {
      fontSize: '1.2em',
      marginBottom: '0.5em',
      fontStyle: 'italic',
    },
    icon: {
      color: Orange[900],
    },
  };
};

export default DefectToolTip;
