import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import {
  FormModal,
  FormModalContent,
  FormModalHeader,
  FormModalAlert,
  FormModalBreadCrumbs,
} from 'components/Forms/FormModal';
import { REGULATOR_TREE_QUERY } from 'components/Regulators/queries';
import { CREATE_SECTION_MUTATION } from '../queries';
import { useToast } from 'contexts/toast';
import useForm from 'hooks/useForm';

const FORM_DEFAULTS = {
  name: '',
};

const CreateSectionFormView = (props) => {
  const { onClose, parentType, handleSubmit, isSubmitting, breadCrumbsLabels, form, isEditing } =
    props;

  const title = parentType === 'Regulator' ? 'Module' : 'Subject';

  return (
    <FormModal isOpen={true}>
      <FormModalHeader
        title={`New ${title}`}
        submitBtnText={`Save ${title}`}
        onClose={onClose}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onReset={() => form.reset()}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      />
      {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <Box display="flex" justifyContent="center" mt="3em" sx={{ width: '100%' }}>
          <TextField
            label="Name"
            variant="outlined"
            size="small"
            sx={{ width: '40%' }}
            value={form.values.name}
            {...form.props.name}
          />
        </Box>
      </FormModalContent>
    </FormModal>
  );
};

const CreateSectionForm = ({ parent, onClose, breadCrumbsLabels }) => {
  const { regulatorId } = useParams();
  const toastMessage = useToast();
  const form = useForm(FORM_DEFAULTS);
  const parentType = parent.type || parent.__typename;

  const relationalType = parentType === 'Regulator' ? 'AscentModule' : 'Subject';

  const [createSection, { loading }] = useMutation(CREATE_SECTION_MUTATION, {
    variables: {
      ...form.values,
      ancestorType: parentType,
      ancestorId: parent.id,
      relationalType: relationalType,
    },
    refetchQueries: [{ query: REGULATOR_TREE_QUERY, variables: { id: regulatorId } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    form.setErrors([]);
    try {
      const {
        data: {
          createSection: { errors },
        },
      } = await createSection();

      if (errors.length) return form.setErrors(errors);
      onClose();

      toastMessage('success', 'Section created successfully');
    } catch (error) {
      form.setErrors([error.message]);
    }
  };

  return (
    <CreateSectionFormView
      isSubmitting={loading}
      handleSubmit={handleSubmit}
      breadCrumbsLabels={breadCrumbsLabels}
      onClose={onClose}
      form={form}
      isEditing={false}
      parentType={parentType}
    />
  );
};

export default CreateSectionForm;
