import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import {
  FormModal,
  FormModalAlert,
  FormModalBreadCrumbs,
  FormModalContent,
  FormModalContentLeft,
  FormModalContentRight,
  FormModalHeader,
} from 'components/Forms/FormModal';
import { QuestionsFieldSet } from 'components/Forms';

const RuleUpsertFormView = ({
  form,
  isEditing,
  isSubmitting,
  handleSubmit,
  breadCrumbsLabels,
  onClose,
  questions,
}) => {
  const sx = useSx();

  return (
    <FormModal isOpen={true}>
      <FormModalHeader
        title={`${isEditing ? 'Edit' : 'New'} Rule`}
        submitBtnText="Save Rule"
        onClose={onClose}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onReset={() => form.reset()}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      />
      {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <FormModalContentLeft>
          <TextField
            {...form.props.number}
            size="small"
            label="Rule Number"
            value={form.values.number}
            sx={sx.field}
          />
          <TextField
            {...form.props.title}
            size="small"
            label="Rule Title"
            value={form.values.title}
            sx={sx.field}
          />
          <DatePicker
            {...form.props.startsAt}
            label="Start Date"
            value={form.values.startsAt}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
          <DatePicker
            {...form.props.endsAt}
            label="End Date"
            value={form.values.endsAt}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
          <DatePicker
            {...form.props.publishedDate}
            label="Published Date"
            value={form.values.publishedDate}
            renderInput={(params) => <TextField {...params} size="small" sx={sx.field} />}
          />
          <QuestionsFieldSet questions={questions} formProps={form.props} />
        </FormModalContentLeft>
        <FormModalContentRight>
          <TextField
            {...form.props.text}
            value={form.values.text}
            multiline
            label="Text"
            sx={sx.field}
          />
        </FormModalContentRight>
      </FormModalContent>
    </FormModal>
  );
};

const useSx = () => ({
  contentLeft: {
    flex: 1,
  },
  field: {
    mt: 0.8,
    mb: 2,
    width: '100%',
  },
  contentRight: {
    flex: 2,
  },
});

export default RuleUpsertFormView;
