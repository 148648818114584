// Libs
import { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';

// Local Components
import RuleVersionExplorerView from 'components/Rules/RuleVersionExplorer/index.view';
import RuleVersionUpsertForm from '../RuleVersionUpsertForm';
import { RequirementVersionUpsertForm } from 'components/Requirements';
import { SupportingInformationVersionUpsertForm } from 'components/SupportingInformations';

// Hooks
import { useToast } from 'contexts/toast';

// Static Assets
import {
  UPDATE_RULE_CHILDREN_MUTATION,
  NEO_RULE_QUERY,
  NEO_RULE_VERSION_QUERY,
  RULE_VERSION_APPEARING_PASSAGES_QUERY,
} from 'components/Rules/queries';

const RuleVersionExplorer = ({ setIsUpdating }) => {
  const toastMessage = useToast();
  const { ruleId, ruleVersionId } = useParams();
  const [ruleVersionUpsertFormProps, setRuleVersionUpsertFormProps] = useState({ show: false });
  const clearRuleVersionUpsertFormProps = () => setRuleVersionUpsertFormProps({ show: false });

  const [updatePassageVersionFormProps, setUpdatePassageVersionFormProps] = useState({
    passageVersion: null,
  });
  const clearUpdatePassageVersionFormProps = () =>
    setUpdatePassageVersionFormProps({ passageVersion: null });

  const {
    loading: isLoadingRule,
    error: fetchRuleError,
    data: { rule } = {},
  } = useQuery(NEO_RULE_QUERY, {
    variables: { id: ruleId },
    skip: !ruleId,
  });

  const {
    loading: isLoadingRuleVersion,
    error: fetchRuleVersionError,
    data: { ruleVersion = {} } = {},
  } = useQuery(NEO_RULE_VERSION_QUERY, {
    variables: { id: ruleVersionId || rule?.currentVersion?.id },
    skip: isLoadingRule,
  });

  const {
    loading: isLoadingAppearingPassageVersions,
    error: fetchAppearingPassageVersionError,
    data: { ruleVersion: { appearingPassageVersions = {} } = {} } = {},
  } = useQuery(RULE_VERSION_APPEARING_PASSAGES_QUERY, {
    variables: {
      id: ruleVersionId || rule?.currentVersion?.id,
    },
    skip: isLoadingRule,
  });

  const [updateRuleChildren] = useMutation(UPDATE_RULE_CHILDREN_MUTATION, {
    refetchQueries: [
      {
        query: RULE_VERSION_APPEARING_PASSAGES_QUERY,
        variables: {
          id: ruleVersionId || rule?.currentVersion?.id,
        },
      },
    ],
  });

  const updatePassagePosition = useCallback(
    async (passageId, passageType, position) => {
      setIsUpdating(true);

      try {
        const {
          data: {
            updateRuleChildren: { errors },
          },
        } = await updateRuleChildren({
          variables: {
            position,
            id: passageId,
            childType: passageType,
          },
          update(cache) {
            cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
          },
        });

        setIsUpdating(false);

        if (errors.length) {
          toastMessage('error', errors.join(', '));

          return false;
        }

        toastMessage('success', 'Successfully Reordered');

        return true;
      } catch (error) {
        setIsUpdating(false);
        toastMessage('error', error.message);

        return false;
      }
    },
    [toastMessage, setIsUpdating, updateRuleChildren]
  );

  if (!rule || isLoadingRule || isLoadingRuleVersion || isLoadingAppearingPassageVersions)
    return null;

  const ruleAncestors = [...rule?.ancestors?.map((ancestor) => ancestor.name), rule.name] || [];
  const handleRuleVersionCreate = () =>
    setRuleVersionUpsertFormProps({
      show: true,
      mode: 'create',
      rule,
      ruleVersion: { number: ruleVersion.number, title: ruleVersion.title, text: ruleVersion.text },
    });

  const handleRuleVersionEdit = () =>
    setRuleVersionUpsertFormProps({ show: true, mode: 'edit', rule, ruleVersion });

  const refetchPreviousVersionQuery = [
    {
      query: NEO_RULE_VERSION_QUERY,
      variables: { id: rule.versions.nodes[0].id },
    },
  ];

  const handlePassageVersionEdit = (passageVersion) =>
    setUpdatePassageVersionFormProps({
      passageVersion,
    });

  const refetchQueries = [
    {
      query: NEO_RULE_QUERY,
      variables: { id: rule.id },
    },
  ];

  return (
    <>
      {ruleVersionUpsertFormProps.show && (
        <RuleVersionUpsertForm
          onClose={clearRuleVersionUpsertFormProps}
          breadCrumbsLabels={ruleAncestors}
          refetchQueries={[...refetchQueries, ...refetchPreviousVersionQuery]}
          {...ruleVersionUpsertFormProps}
        />
      )}

      {updatePassageVersionFormProps.passageVersion &&
        updatePassageVersionFormProps.passageVersion.__typename === 'RequirementVersion' && (
          <RequirementVersionUpsertForm
            isEditing
            disableDates={true}
            refetchQueries={refetchQueries}
            breadCrumbsLabels={ruleAncestors}
            onClose={clearUpdatePassageVersionFormProps}
            requirementVersion={updatePassageVersionFormProps.passageVersion}
            requirementId={updatePassageVersionFormProps.passageVersion?.passage?.id}
          />
        )}

      {updatePassageVersionFormProps.passageVersion?.__typename ===
        'SupportingInformationVersion' && (
        <SupportingInformationVersionUpsertForm
          isEditing
          disableDates={true}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          onClose={clearUpdatePassageVersionFormProps}
          supportingInformationVersion={updatePassageVersionFormProps.passageVersion}
          supportingInformationId={updatePassageVersionFormProps.passageVersion.passage.id}
        />
      )}

      <RuleVersionExplorerView
        fetchRuleError={fetchRuleError}
        fetchRuleVersionError={fetchRuleVersionError}
        fetchAppearingPassageVersionError={fetchAppearingPassageVersionError}
        isLoadingRuleVersion={isLoadingRuleVersion}
        isLoadingAppearingPassageVersions={isLoadingAppearingPassageVersions}
        ruleVersions={rule.versions.nodes}
        ruleVersion={ruleVersion}
        rule={rule}
        appearingPassageVersions={appearingPassageVersions?.nodes || []}
        detachedPassageVersions={rule.detachedPassageVersions?.nodes || []}
        automaticEnrichment={rule.enrichmentFlow === 'auto'}
        updatePassagePosition={updatePassagePosition}
        refetchQueries={refetchQueries}
        handleRuleVersionCreate={handleRuleVersionCreate}
        handleRuleVersionEdit={handleRuleVersionEdit}
        handlePassageVersionEdit={handlePassageVersionEdit}
      />
    </>
  );
};

export default RuleVersionExplorer;
