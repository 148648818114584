import { useMemo, useState } from 'react';
import { useLocationSearch } from 'utils/searchParams';
import { truncate } from 'lodash';
import { Draggable } from 'react-beautiful-dnd';
import Chip from '@mui/material/Chip';
import EditIcon from '@mui/icons-material/Edit';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Button from '@mui/material/Button';
import { Blue, Gray, Yellow } from 'components/App/colors';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import formatDate from 'utils/formatDate';

const MAX_CONTENT_SHOW_LENGTH = 400;

const RuleChildrenRow = ({
  id,
  type,
  versionId,
  index,
  number,
  content,
  startDate,
  endDate,
  startDateChipLabel,
  isMutable,
  onEdit,
  onClone,
  onDelete,
  onCopyId,
}) => {
  const sx = useSx();
  const [isFullContent, setIsFullContent] = useState(false);
  const truncatedContent = useMemo(
    () => truncate(content, { length: MAX_CONTENT_SHOW_LENGTH }),
    [content]
  );
  const highlightIds = useLocationSearch().params?.highlightIds?.split(',') || [];
  const shouldBeHighlighted = highlightIds?.includes(id) || highlightIds?.includes(versionId);

  return (
    <Draggable draggableId={id} index={index}>
      {(provided, snapshot) => (
        <TableRow
          key={id}
          ref={provided.innerRef}
          sx={{
            verticalAlign: 'baseline',
            backgroundColor: shouldBeHighlighted
              ? Yellow[900]
              : snapshot.isDragging
              ? Blue[50]
              : '',
          }}
          {...provided.draggableProps}
        >
          <TableCell sx={sx.iconCell} width={40}>
            <div {...provided.dragHandleProps}>
              <IconButton disableRipple>
                <DragIndicatorIcon sx={{ color: Gray[500] }} />
              </IconButton>
            </div>
          </TableCell>
          <TableCell sx={sx.dateCell} width={130}>
            <Typography variant="body2">{type}</Typography>
          </TableCell>
          <TableCell sx={sx.dateCell} width={170}>
            <Typography variant="body2">{number}</Typography>
          </TableCell>
          <TableCell sx={sx.contentCell}>
            {/* Why a textfield here and not a box? */}
            <TextField
              disabled
              multiline
              fullWidth
              variant="standard"
              sx={sx.ruleContent}
              value={isFullContent ? content : truncatedContent}
            />
            {content.length > MAX_CONTENT_SHOW_LENGTH && (
              <Button
                component="span"
                sx={{ color: Blue[200] }}
                onClick={() => setIsFullContent((prevState) => !prevState)}
              >
                <Typography variant="overline">
                  {isFullContent ? 'read less' : 'read more'}
                </Typography>
              </Button>
            )}
          </TableCell>
          <TableCell data-testid="start-date" width={120}>
            {formatDate(startDate)}
            {!!startDateChipLabel && (
              <Chip size="small" component="span" label={startDateChipLabel} sx={{ mt: 2 }} />
            )}
          </TableCell>
          <TableCell data-testid="end-date" width={120}>
            {formatDate(endDate)}
          </TableCell>
          {isMutable && (
            <>
              <TableCell sx={sx.iconCell} width={40}>
                <Tooltip title="Clone" placement="top">
                  <IconButton onClick={onClone}>
                    <FileCopyIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell sx={sx.iconCell} width={40}>
                <Tooltip title="Edit" placement="top">
                  <IconButton onClick={onEdit}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
              <TableCell sx={sx.iconCell} width={40}>
                <Tooltip title="Delete" placement="top">
                  <IconButton onClick={onDelete}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </TableCell>
            </>
          )}
          <TableCell sx={sx.iconCell} width={40}>
            <Tooltip title="Copy ID" placement="top">
              <IconButton onClick={onCopyId}>
                <CopyAllIcon />
              </IconButton>
            </Tooltip>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

const useSx = () => ({
  dateCell: {
    whiteSpace: 'nowrap',
  },
  contentCell: {
    overflow: 'hidden',
  },
  iconCell: {
    padding: '0',
  },
  ruleContent: {
    '& .MuiInput-root, & .MuiInput-root:before, & .MuiInput-root:after, & textarea': {
      color: '#333333 !important',
      textFillColor: '#333333 !important',
      border: '0 !important',
      cursor: 'text',
    },
  },
});

export default RuleChildrenRow;
