import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

import useForm from 'hooks/useForm';
import { useToast } from 'contexts/toast';
import RuleUpsertFormView from 'components/Rules/RuleUpsertForm/index.view';
import { REGULATOR_TREE_QUERY } from 'components/Regulators/queries';
import { CREATE_RULE_MUTATION, UPDATE_RULE_MUTATION } from 'components/Rules/queries';
import { toISODateString } from 'utils/dateAndTime';
import { QUESTIONS_QUERY } from 'components/Questions/queries';

const RuleUpsertForm = ({ rule, onClose, breadCrumbsLabels, parent, questions }) => {
  const { regulatorId } = useParams();

  const formDefaults = {
    text: rule?.text || '',
    title: rule?.title || '',
    number: rule?.number || '',
    endsAt: rule?.endsAt || null,
    startsAt: rule?.startsAt || null,
    publishedDate: rule?.publishedDate || null,
    newAnswers: [],
    answerIds: [],
  };

  const isEditing = !!rule;
  const toastMessage = useToast();
  const form = useForm(formDefaults);

  const createAttrs = {
    valuesUpdates: { subjectId: parent.id },
    mutationKey: 'createRule',
    mutationQuery: CREATE_RULE_MUTATION,
  };

  const updateAttrs = {
    valuesUpdates: { id: rule?.id },
    mutationKey: 'updateRule',
    mutationQuery: UPDATE_RULE_MUTATION,
  };

  const { valuesUpdates, mutationKey, mutationQuery } = isEditing ? updateAttrs : createAttrs;
  const [upsertRule, { loading: mutationLoading }] = useMutation(mutationQuery, {
    refetchQueries: [
      { query: REGULATOR_TREE_QUERY, variables: { id: regulatorId } },
      { query: QUESTIONS_QUERY, variables: { regulatorId: regulatorId } },
    ],
    variables: {
      ...form.values,
      ...valuesUpdates,
      startsAt: toISODateString(form.values.startsAt),
      endsAt: toISODateString(form.values.endsAt),
      publishedDate: toISODateString(form.values.publishedDate),
    },
    awaitRefetchQueries: true,
  });

  const handleSubmit = async () => {
    form.setErrors([]);

    try {
      const {
        data: {
          [mutationKey]: { errors },
        },
      } = await upsertRule();

      if (errors.length) return form.setErrors(errors);

      onClose();

      toastMessage('success', `Rule ${isEditing ? 'updated' : 'created'} successfully`);
    } catch (error) {
      form.setErrors([error.message]);
    }
  };

  return (
    <RuleUpsertFormView
      form={form}
      isEditing={isEditing}
      handleSubmit={handleSubmit}
      isSubmitting={mutationLoading}
      onClose={onClose}
      breadCrumbsLabels={breadCrumbsLabels}
      questions={questions}
    />
  );
};

export default RuleUpsertForm;
