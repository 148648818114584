export const compactObject = (obj) =>
  Object.entries(obj).reduce((acc, [key, val]) => (val ? { ...acc, [key]: val } : acc), {});

export const filterObject = (obj, callback) =>
  Object.entries(obj)
    .filter(callback)
    .reduce((acc, [key, value]) => {
      acc[key] = value;

      return acc;
    }, {});

export const dropObjectKeys = (obj, keys) => filterObject(obj, ([key]) => !keys.includes(key));

export const selectObjectKeys = (obj, keys) => filterObject(obj, ([key]) => keys.includes(key));
