import { useState, useContext, createContext } from 'react';
import { ClosableToast } from '../../components/Alerts/Toast';

export const ToastContext = createContext(() => {});

export const ToastProvider = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  const handleClose = (key) =>
    setToasts((currentState) => currentState.filter((toast) => toast.key !== key));

  const toastMessage = (severity, message) =>
    setToasts((currentState) => [
      ...currentState,
      {
        message,
        severity,
        key: new Date().getTime(),
      },
    ]);

  return (
    <ToastContext.Provider value={toastMessage}>
      {toasts.map(({ key, message, severity }, index) => (
        <ClosableToast
          key={key}
          message={message}
          severity={severity}
          sx={{ mt: index * 8 }}
          onClose={() => handleClose(key)}
        />
      ))}
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
