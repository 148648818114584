import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import { useToast } from 'contexts/toast';
import { useQuery, useMutation } from '@apollo/client';
import { DELETE_SECTION_MUTATION } from 'components/Sections/queries';
import { UPDATE_RULE_MUTATION, DELETE_RULE_MUTATION } from 'components/Rules/queries';
import {
  REGULATOR_TREE_QUERY,
  UPDATE_REGULATOR_MUTATION,
  UPDATE_SECTION_MUTATION,
} from 'components/Regulators/queries';
import RuleBookEditView, {
  RuleBookEditLoader,
} from 'components/Regulators/RuleBookEdit/index.view';
import { QUESTIONS_QUERY } from 'components/Questions/queries';

const RuleBookEditWithData = () => {
  const toastMessage = useToast();
  const { regulatorId } = useParams();

  const isSection = (type) => ['AscentModule', 'Subject'].includes(type);

  // Query and Mutation Definitions

  const {
    data: regulatorData,
    error: regulatorError,
    loading: regulatorLoading,
  } = useQuery(REGULATOR_TREE_QUERY, { variables: { id: regulatorId } });

  const { data: { questions = {} } = {} } = useQuery(QUESTIONS_QUERY, {
    variables: { regulatorId: regulatorId },
  });

  const refetchQueriesOptions = {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: REGULATOR_TREE_QUERY, variables: { id: regulatorId } }],
  };

  const [deleteRule, { loading: deleteRuleLoading }] = useMutation(
    DELETE_RULE_MUTATION,
    refetchQueriesOptions
  );
  const [updateRule, { loading: updateRuleLoading }] = useMutation(
    UPDATE_RULE_MUTATION,
    refetchQueriesOptions
  );
  const [deleteSection, { loading: deleteSectionLoading }] = useMutation(
    DELETE_SECTION_MUTATION,
    refetchQueriesOptions
  );
  const [updateSection, { loading: updateSectionLoading }] = useMutation(
    UPDATE_SECTION_MUTATION,
    refetchQueriesOptions
  );
  const [updateRegulator, { loading: updateRegulatorLoading }] = useMutation(
    UPDATE_REGULATOR_MUTATION,
    refetchQueriesOptions
  );

  const isLoading =
    deleteRuleLoading ||
    updateRuleLoading ||
    deleteSectionLoading ||
    updateSectionLoading ||
    updateRegulatorLoading;

  // Support Action Handlers

  const performMutation = useCallback(
    async (mutation, key, options) => {
      try {
        const {
          data: {
            [key]: { errors },
          },
        } = await mutation(options);

        if (errors.length) return toastMessage('error', errors.join(', '));

        toastMessage('success', 'Action successful');
      } catch (error) {
        toastMessage('error', error.message);
      }
    },
    [toastMessage]
  );

  const updateNodePosition = (id, type, position) =>
    performMutation(
      isSection(type) ? updateSection : updateRule,
      isSection(type) ? 'updateSection' : 'updateRule',
      { variables: { id, position } }
    );

  const deleteNode = (id, type) =>
    performMutation(
      isSection(type) ? deleteSection : deleteRule,
      isSection(type) ? 'deleteSection' : 'deleteRule',
      { variables: { id } }
    );

  const updateNodeText = (id, type, value) => {
    return performMutation(
      isSection(type) ? updateSection : updateRegulator,
      isSection(type) ? 'updateSection' : 'updateRegulator',
      { variables: { id, name: value } }
    );
  };

  // Render Component

  if (regulatorLoading) return <RuleBookEditLoader />;
  if (regulatorError) return <Alert severity="error">Something went wrong :(</Alert>;

  const { regulator } = regulatorData;

  const getSectionHierarchy = (id, type) => {
    if (isSection(type)) {
      for (const module of regulator.sections.nodes) {
        if (id === module.id) return [regulator.name, module.name];

        if (type === 'Subject') {
          for (const subject of module.sections.nodes) {
            if (id === subject.id) return [regulator.name, module.name, subject.name];
          }
        }
      }
    }

    return [regulator.name];
  };

  return (
    <RuleBookEditView
      regulator={regulator}
      isLoading={isLoading}
      deleteNode={deleteNode}
      updateNodeText={updateNodeText}
      updateNodePosition={updateNodePosition}
      getSectionHierarchy={getSectionHierarchy}
      questions={questions?.nodes}
    />
  );
};

export default RuleBookEditWithData;
