// Libs
import { useState } from 'react';

// Local Components
import AddPassageContextMenuView from './index.view';
import { RequirementCreateForm } from 'components/Requirements';
import { SupportingInformationCreateForm } from 'components/SupportingInformations';

// Static Assets
import { NEO_RULE_QUERY, RULE_VERSION_APPEARING_PASSAGES_QUERY } from 'components/Rules/queries';

const AddPassageContextMenu = ({ rule, ruleVersion, position, show = true, ...restProps }) => {
  const [activePassageType, setActivePassageType] = useState(null);

  const ruleAncestors = [...rule?.ancestors?.map((ancestor) => ancestor.name), rule.name] || [];

  const refetchQueries = [
    {
      query: NEO_RULE_QUERY,
      variables: { id: rule.id },
    },
    {
      query: RULE_VERSION_APPEARING_PASSAGES_QUERY,
      variables: { id: ruleVersion.id },
    },
  ];

  return (
    <>
      {activePassageType === 'requirement' && (
        <RequirementCreateForm
          disableDates
          ruleVersion={ruleVersion}
          requirement={{ position }}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          onClose={() => setActivePassageType(null)}
        />
      )}

      {activePassageType === 'si' && (
        <SupportingInformationCreateForm
          disableDates
          ruleVersion={ruleVersion}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          supportingInformation={{ position }}
          onClose={() => setActivePassageType(null)}
        />
      )}

      {show && <AddPassageContextMenuView onItemClick={setActivePassageType} {...restProps} />}
    </>
  );
};

export default AddPassageContextMenu;
