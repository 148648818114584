import Alert from '@mui/material/Alert';

const StaticAlert = ({ children, onClose = () => {}, severity, visible = true, ...restProps }) => {
  if (!visible) return null;

  return (
    <Alert {...restProps} severity={severity} sx={{ alignItems: 'center', my: 1, ...restProps.sx }}>
      {children}
    </Alert>
  );
};

export default StaticAlert;
