import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';

import { ApiProvider } from './contexts/api';
import { AuthProvider } from './contexts/auth';
import { EnvProvider } from './contexts/env';
import { LoggerProvider } from './contexts/logger';
import { RollbarProvider } from './contexts/rollbar';

ReactDOM.render(
  <React.StrictMode>
    <EnvProvider>
      <RollbarProvider>
        <LoggerProvider>
          <AuthProvider>
            <ApiProvider>
              <App />
            </ApiProvider>
          </AuthProvider>
        </LoggerProvider>
      </RollbarProvider>
    </EnvProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
