import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';

export const StyleCacheProvider = ({ children }) => {
  const cache = createCache({ key: 'mui-style' });

  // This cache is require to set the "compat" flag, which disables warnings for
  // common and valid uses of "first-child" and "last-child" pseudo selectors.
  // https://github.com/emotion-js/emotion/issues/1105#issuecomment-1058225197
  cache.compat = true;

  return <CacheProvider value={cache}>{children}</CacheProvider>;
};
