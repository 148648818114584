// Libs
import { useState } from 'react';

// Local Components
import AppearingPassageVersionView from './index.view';
import { RequirementVersionUpsertForm } from 'components/Requirements';
import { SupportingInformationVersionUpsertForm } from 'components/SupportingInformations';

// Static Assets
import { NEO_RULE_QUERY } from 'components/Rules/queries';

const AppearingPassageVersion = ({
  passageVersion,
  rule,
  ruleVersion,
  index,
  dndProps,
  appearingPassageVersions,
  handlePassageVersionEdit,
  openPassageDialog,
  isMarkdownRendered,
}) => {
  const [createFormProps, setCreateFormProps] = useState({});
  const clearCreateFormProps = () => setCreateFormProps({});

  const ruleAncestors = [...rule?.ancestors?.map((ancestor) => ancestor.name), rule.name] || [];

  const createPassageVersion = (type, passageId) => setCreateFormProps({ type, passageId });

  const refetchQueries = [
    {
      query: NEO_RULE_QUERY,
      variables: { id: rule.id },
    },
  ];

  const { endsAt, startsAt, ...nonDateFields } = passageVersion;

  return (
    <>
      {createFormProps.type === 'Requirement Version' && (
        <RequirementVersionUpsertForm
          onClose={clearCreateFormProps}
          isEditing={false}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          requirementId={createFormProps.passageId}
          requirementVersion={nonDateFields}
          ruleVersion={ruleVersion}
          disableDates={true}
        />
      )}

      {createFormProps.type === 'Supporting Information Version' && (
        <SupportingInformationVersionUpsertForm
          onClose={clearCreateFormProps}
          isEditing={false}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          supportingInformationId={createFormProps.passageId}
          supportingInformationVersion={nonDateFields}
          ruleVersion={ruleVersion}
          disableDates={true}
        />
      )}
      <AppearingPassageVersionView
        {...dndProps}
        passageVersion={passageVersion}
        rule={rule}
        ruleVersion={ruleVersion}
        index={index}
        appearingPassageVersions={appearingPassageVersions}
        createPassageVersion={createPassageVersion}
        handlePassageVersionEdit={handlePassageVersionEdit}
        openPassageDialog={openPassageDialog}
        isMarkdownRendered={isMarkdownRendered}
      />
    </>
  );
};

export default AppearingPassageVersion;
