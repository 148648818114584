export const Blue = {
  300: '#183460',
  200: '#485D8E',
  100: '#7794D9',
  50: '#EFF4F9',
};

export const Gray = {
  700: '#333333',
  600: '#4D4D4D',
  500: '#878787',
  400: '#BFBFBF',
  300: '#E6E6E6',
  200: '#E1E1E1',
  100: '#FFFFFF',
};

export const Green = {
  700: '#0c9913',
};

export const Red = {
  900: '#801313',
};

export const Yellow = {
  900: '#FFF4E5',
};

export const Orange = {
  900: '#ed6c02',
};
