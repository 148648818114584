import { useMutation } from '@apollo/client';
import useForm from 'hooks/useForm';
import useQueryParams from 'hooks/useQuery';
import { useParams } from 'react-router-dom';
import { useToast } from 'contexts/toast';
import { CREATE_SUPPORTING_INFORMATION_MUTATION } from 'components/SupportingInformations/queries';
import SupportingInformationCreateFormView from 'components/SupportingInformations/SupportingInformationCreateForm/index.view';
import { useState } from 'react';
import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';

const buildFormDefaults = (ruleVersion, disableDates) => ({
  aic: '',
  number: '',
  content: '',
  endsAt: disableDates ? null : toLocalizedDate(ruleVersion.endsAt),
  startsAt: toLocalizedDate(ruleVersion.startsAt),
});

const SupportingInformationCreateForm = ({
  onClose,
  breadCrumbsLabels,
  ruleVersion,
  disableDates = false,
  supportingInformation = {}, // contains only values for fields that needs to be passed to the mutation but no input field
  refetchQueries = [],
}) => {
  const toastMessage = useToast();
  const queryParams = useQueryParams();
  const pathParams = useParams();
  const ruleId = queryParams.get('ruleId') || pathParams.ruleId;

  const form = useForm({
    ...buildFormDefaults(ruleVersion, disableDates),
    ...supportingInformation,
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createSupportingInformation] = useMutation(CREATE_SUPPORTING_INFORMATION_MUTATION);

  const handleSubmit = async () => {
    form.setErrors([]);
    setIsSubmitting(true);

    try {
      const {
        data: {
          createSupportingInformation: { errors },
        },
      } = await createSupportingInformation({
        variables: {
          ruleId,
          ...form.values,
          startsAt: toISODateString(form.values.startsAt),
          endsAt: toISODateString(form.values.endsAt),
        },
        refetchQueries: refetchQueries,
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'rule' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
        },
      });

      setIsSubmitting(false);
      if (errors.length) return form.setErrors(errors);
      onClose();
      toastMessage('success', 'Supporting Information created successfully.');
    } catch (error) {
      setIsSubmitting(false);
      form.setErrors([error.message]);
    }
  };

  return (
    <SupportingInformationCreateFormView
      form={form}
      isEditing={false}
      isSubmitting={isSubmitting}
      handleSubmit={handleSubmit}
      onClose={onClose}
      breadCrumbsLabels={breadCrumbsLabels}
      disableDates={disableDates}
    />
  );
};

export default SupportingInformationCreateForm;
