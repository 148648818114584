import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import { DatePicker } from '@mui/x-date-pickers';

import {
  FormModalHeader,
  FormModalAlert,
  FormModal,
  FormModalBreadCrumbs,
  FormModalContent,
  FormModalContentLeft,
  FormModalContentRight,
} from 'components/Forms/FormModal';
import { AicField } from 'components/Forms';

const AIC_MAPPING = [
  { label: 'RA', value: 'RA' },
  { label: 'RIC', value: 'RIC' },
  { label: 'RM', value: 'RM' },
];

const FREQUENCY_MAPPING = [
  { label: 'No Frequency', value: 'No Frequency' },
  { label: 'Daily', value: 'daily' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Annually', value: 'annually' },
  { label: 'Bi-Annually', value: 'bi-annually' },
];

export const RequirementCreateFormView = ({
  isSubmitting,
  onClose,
  handleSubmit,
  breadCrumbsLabels,
  form,
  isEditing,
  disableDates,
}) => {
  return (
    <FormModal isOpen={true}>
      <FormModalHeader
        title="New Requirement"
        submitBtnText="Save Requirement"
        isSubmitting={isSubmitting}
        onSubmit={handleSubmit}
        onReset={() => form.reset()}
        onClose={onClose}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      />
      {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <FormModalContentLeft>
          <TextField
            {...form.props.number}
            size="small"
            label="Number"
            value={form.values.number}
          />
          <TextField
            {...form.props.summary}
            multiline
            size="small"
            label="Summary"
            value={form.values.summary}
          />
          <DatePicker
            {...form.props.startsAt}
            label="Start Date"
            value={form.values.startsAt}
            renderInput={(params) => <TextField size="small" {...params} />}
            disabled={disableDates}
          />
          <DatePicker
            {...form.props.endsAt}
            label="End Date"
            value={form.values.endsAt}
            renderInput={(params) => <TextField size="small" {...params} />}
            disabled={disableDates}
          />
          <FormControl size="small" fullWidth>
            <InputLabel size="small" id="frequency-label">
              Frequency
            </InputLabel>
            <Select
              {...form.props.frequency}
              labelId="frequency-label"
              id="frequency"
              value={form.values.frequency || ''}
              label="Frequency"
              size="small"
            >
              {FREQUENCY_MAPPING.map(({ label, value }) => (
                <MenuItem size="small" value={value} key={value}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <AicField aicMapping={AIC_MAPPING} value={form.values.aic} {...form.props.aic} />
        </FormModalContentLeft>
        <FormModalContentRight>
          <TextField
            {...form.props.content}
            value={form.values.content}
            multiline
            fullWidth
            label="Content"
          />
        </FormModalContentRight>
      </FormModalContent>
    </FormModal>
  );
};

export default RequirementCreateFormView;
