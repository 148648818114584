import Alert from '@mui/material/Alert';
import { ConfirmDialog } from 'components/Forms';

const DeleteSupportingInformationVersion = ({
  isOpen,
  handleClose,
  handleSubmit,
  errors,
  allowEntityDeletion,
}) => {
  const sx = useSx();

  return (
    <ConfirmDialog
      isOpen={isOpen}
      onCancel={handleClose}
      onConfirm={handleSubmit}
      title="Delete Supporting Information Version"
      description={'Are sure you want to delete this version?'}
      confirmText={`Delete ${allowEntityDeletion ? 'Anyway' : ''}`}
    >
      {!!errors.length && (
        <Alert severity="error" sx={sx.alert}>
          {errors[0]}
        </Alert>
      )}
    </ConfirmDialog>
  );
};

const useSx = () => ({
  alert: {
    alignItems: 'center',
    marginTop: '1em',
  },
});

export default DeleteSupportingInformationVersion;
