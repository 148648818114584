// Libs
import { useState } from 'react';

// MUI Components
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import Menu from '@mui/material/Menu';
import MenuList from '@mui/material/MenuList';

// MUI Icons
import MoreVertIcon from '@mui/icons-material/MoreVertOutlined';

const ContextMenu = ({
  children,
  identifier,
  title,
  subtitles,
  icon = <MoreVertIcon />,
  sxProps = {},
  dense = true,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClose = () => setAnchorEl(null);

  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const sx = useSx();

  return (
    <>
      <IconButton
        data-testid={`${identifier}-context-icon`}
        sx={{ ...sx.button(dense), ...sxProps }}
        disabled={disabled}
        onClick={handleClick}
      >
        {icon}
      </IconButton>
      <Menu
        data-testid={`${identifier}-context-menu`}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onClick={handleClose}
        disablepadding="true"
      >
        <ContextHeader title={title} subtitles={subtitles} />
        <MenuList dense>{children}</MenuList>
      </Menu>
    </>
  );
};

const ContextHeader = ({ title, subtitles = [] }) => {
  const sx = useSx();

  if (!title) return null;

  return (
    <>
      <List
        sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        data-testid="context-info"
        subheader={
          <ListSubheader component="div" sx={sx.listSubheader}>
            {title}
          </ListSubheader>
        }
      >
        {subtitles?.map((subtitle, index) => (
          <ListItem key={`subhead${index}`} sx={sx.listItem}>
            {subtitle}
          </ListItem>
        ))}
      </List>
      <Divider />
    </>
  );
};

const useSx = () => ({
  root: { paddingTop: '110' },
  listSubheader: {
    color: 'inherit',
    lineHeight: '1.5',
    fontSize: '1rem',
  },
  listItem: {
    fontSize: 'small',
    padding: '1px 16px',
  },
  button: (dense) => ({
    ...(dense && {
      padding: '2px',
      paddingTop: 0,
      height: 'calc(1em - 4px)',
      width: 'calc(1em - 4px)',
    }),
  }),
});

export default ContextMenu;
