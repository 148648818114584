import { gql } from '@apollo/client';

export const QUESTIONS_QUERY = gql`
  query Questions($regulatorId: String) {
    questions(regulatorId: $regulatorId) {
      nodes {
        id
        content
        answers {
          id
          content
        }
      }
    }
  }
`;
