import { gql } from '@apollo/client';

export const SUPPORTING_INFORMATION_QUERY = gql`
  query SupportingInformation($id: ID!) {
    supportingInformation(id: $id) {
      id
      aic
      baseContent
      endsAt
      number
      startsAt
      content
    }
  }
`;

export const CREATE_SUPPORTING_INFORMATION_MUTATION = gql`
  mutation CreateSupportingInformation(
    $aic: String!
    $number: String
    $startsAt: ISO8601Date!
    $endsAt: ISO8601Date
    $ruleId: String!
    $content: String!
    $position: Int
  ) {
    createSupportingInformation(
      input: {
        aic: $aic
        baseContent: $content
        content: $content
        endsAt: $endsAt
        ruleId: $ruleId
        number: $number
        startsAt: $startsAt
        position: $position
      }
    ) {
      supportingInformation {
        id
        aic
        startsAt
        endsAt
        number
      }
      errors
    }
  }
`;

export const SUPPORTING_INFORMATION_VERSION_CREATE_MUTATION = gql`
  mutation CreateSupportingInformationVersion(
    $aic: String
    $content: String!
    $endsAt: ISO8601Date
    $number: String
    $startsAt: ISO8601Date!
    $supportingInformationId: ID!
  ) {
    createSupportingInformationVersion(
      input: {
        aic: $aic
        baseContent: $content
        content: $content
        endsAt: $endsAt
        number: $number
        startsAt: $startsAt
        supportingInformationId: $supportingInformationId
      }
    ) {
      supportingInformationVersion {
        id
        aic
        number
        content
        startsAt
        endsAt
      }
      errors
    }
  }
`;

export const SUPPORTING_INFORMATION_VERSION_UPDATE_MUTATION = gql`
  mutation UpdateSupportingInformationVersion(
    $id: ID!
    $aic: String
    $number: String
    $startsAt: ISO8601Date!
    $endsAt: ISO8601Date
    $content: String
    $detachOverride: Boolean
  ) {
    updateSupportingInformationVersion(
      input: {
        id: $id
        aic: $aic
        number: $number
        content: $content
        startsAt: $startsAt
        endsAt: $endsAt
        detachOverride: $detachOverride
      }
    ) {
      supportingInformationVersion {
        id
        aic
        number
        content
      }
      errors
    }
  }
`;

export const SUPPORTING_INFORMATION_VERSION_DELETE_MUTATION = gql`
  mutation DeleteSupportingInformationVersion($id: ID!, $allowEntityDeletion: Boolean) {
    deleteSupportingInformationVersion(
      input: { id: $id, allowEntityDeletion: $allowEntityDeletion }
    ) {
      errors
    }
  }
`;
