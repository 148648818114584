import React, { createContext, useContext, useEffect, useState } from 'react';
import SumoLogger from 'sumo-logger';
import { useEnv } from '../env';

export const createLogger = (env) => {
  if (!env || !env.SUMO_LOGIC_ENDPOINT) return null;

  return new SumoLogger({
    clientUrl: window.location.href,
    endpoint: env.SUMO_LOGIC_ENDPOINT,
    hostName: 'client',
  });
};

export const emptyLogger = { log: console.log };

export const LoggerContext = createContext({
  logger: null,
});

export const LoggerProvider = ({ children }) => {
  const env = useEnv();
  const [logger, setLogger] = useState(createLogger(env));

  useEffect(() => !logger && setLogger(createLogger(env)), [env, logger, setLogger]);

  // TODO: don't use Mocked* in the main component
  if (!logger) return <MockedLoggerProvider children={children} />;

  return <LoggerContext.Provider value={{ logger }}>{children}</LoggerContext.Provider>;
};

export const MockedLoggerProvider = ({ logger = emptyLogger, children }) => (
  <LoggerContext.Provider value={{ logger }}>{children}</LoggerContext.Provider>
);

// Return a scoped logger? Allow logging of the Component caller.
export const useLogger = () => useContext(LoggerContext);
