// MUI Components
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

// Local Components
import ContextMenu from 'components/ContextMenu';

const AddPassageContextMenuView = ({ onItemClick, ...restProps }) => (
  <ContextMenu {...restProps}>
    <MenuItem onClick={() => onItemClick('requirement')}>
      <ListItemText>Add Requirement</ListItemText>
    </MenuItem>
    <MenuItem onClick={() => onItemClick('si')}>
      <ListItemText>Add Supporting Information</ListItemText>
    </MenuItem>
  </ContextMenu>
);

export default AddPassageContextMenuView;
