import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';

const ConfirmDialog = ({
  cancelText = 'Cancel',
  children,
  confirmText = 'Confirm',
  isOpen = false,
  onCancel,
  onConfirm,
  title = 'Are you sure?',
  description,
  isSubmitting,
  ...restProps
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby={`alert-dialog-${title}`}
      aria-describedby={`alert-dialog-${description}`}
      {...restProps}
    >
      <DialogTitle sx={{ fontSize: '1.2em' }} data-testid="dialog-title">
        {title}
        {isSubmitting && (
          <CircularProgress
            data-testid="progress"
            color="success"
            size={23}
            sx={{ float: 'right' }}
          />
        )}
      </DialogTitle>
      <DialogContent>
        {description && (
          <DialogContentText data-testid="dialog-description" children={description} />
        )}
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="secondary" data-testid="cancel-button">
          {cancelText}
        </Button>
        <Button onClick={onConfirm} color="secondary" data-testid="confirm-button">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  cancelText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  confirmText: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
};

export default ConfirmDialog;
