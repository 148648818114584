import { useCallback, useEffect, useState } from 'react';
import { useToast } from 'contexts/toast';
import { useMutation } from '@apollo/client';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import { QUESTIONS_QUERY } from 'components/Questions/queries';
import useVersionDelete from 'components/VersionDeleteForm/index.hook';
import RuleChildrenTableView from 'components/Rules/RuleChildrenTable/index.view';
import {
  RULE_CHILDREN_QUERY,
  RULE_QUERY,
  UPDATE_RULE_CHILDREN_MUTATION,
} from 'components/Rules/queries';
import {
  RequirementCreateForm,
  DeleteRequirementVersion,
  RequirementVersionUpsertForm,
} from 'components/Requirements';
import {
  SupportingInformationCreateForm,
  DeleteSupportingInformationVersion,
  SupportingInformationVersionUpsertForm,
} from 'components/SupportingInformations';

const reorderRows = (rows, srcIndex, destIndex) => {
  const result = Array.from(rows);
  const [removed] = result.splice(srcIndex, 1);

  result.splice(destIndex, 0, removed);

  return result;
};
const RuleChildrenTable = ({
  rule,
  ruleVersion,
  ruleChildren,
  ruleAncestors,
  isChildrenMutable,
}) => {
  const toastMessage = useToast();
  const [rows, setRows] = useState([]);
  const copyToClipboard = useCopyToClipboard();
  const [formProps, setFormProps] = useState({ type: null });
  const clearFormProps = () => setFormProps({ type: null });

  const { deleteVersion: deleteReqVersion, props: reqVersionDeleteProps } = useVersionDelete();
  const { deleteVersion: deleteSiVersion, props: siVersionDeleteProps } = useVersionDelete();

  const refetchQueries = [
    {
      query: QUESTIONS_QUERY,
      variables: { regulatorId: rule.regulator.id },
    },
    {
      query: RULE_CHILDREN_QUERY,
      variables: {
        id: rule.id,
        startsAt: ruleVersion?.startsAt,
        endsAt: ruleVersion?.endsAt,
      },
    },
    {
      query: RULE_QUERY,
      variables: {
        id: rule.id,
      },
    },
  ];

  const [updateRuleChildren, { loading: isUpdatingRuleChildren }] = useMutation(
    UPDATE_RULE_CHILDREN_MUTATION,
    { refetchQueries }
  );

  const createSi = () => setFormProps({ type: 'si-create-form' });
  const createRequirement = () => setFormProps({ type: 'requirement-create-form' });

  const upsertSiVersion = (isEditing, supportingInformation) =>
    setFormProps({
      isEditing,
      supportingInformation,
      type: 'si-version-upsert-form',
    });

  const upsertReqVersion = (isEditing, requirement) =>
    setFormProps({
      isEditing,
      requirement,
      type: 'req-version-upsert-form',
    });

  useEffect(() => {
    const newRows = ruleChildren.map((ruleChild) => {
      const effectiveStatus = ruleChild.version.effectiveStatus;
      const isEffective = effectiveStatus === 'effective';
      const versionsCount = ruleChild.versions.pageInfo.totalCount;

      return {
        id: ruleChild.id,
        type: ruleChild.__typename,
        versionId: ruleChild.version.id,
        position: ruleChild.position,
        number: ruleChild.version.number,
        content: ruleChild.version.content,
        startDate: ruleChild.version.startsAt,
        endDate: ruleChild.version.endsAt,
        startDateChipLabel: isEffective ? undefined : effectiveStatus,
        onEdit: () =>
          ruleChild.__typename === 'Requirement'
            ? upsertReqVersion(true, ruleChild)
            : upsertSiVersion(true, ruleChild),
        onClone: () =>
          ruleChild.__typename === 'Requirement'
            ? upsertReqVersion(false, ruleChild)
            : upsertSiVersion(false, ruleChild),
        onDelete: () =>
          ruleChild.__typename === 'Requirement'
            ? deleteReqVersion(ruleChild.version.id, versionsCount)
            : deleteSiVersion(ruleChild.version.id, versionsCount),
        onCopyId: () => copyToClipboard(ruleChild.id, `Copied ${ruleChild.__typename} ID`),
      };
    });

    setRows(newRows);
    // eslint-disable-next-line
  }, [ruleChildren]);

  const updateChildPosition = useCallback(
    async (childId, childType, newPosition) => {
      try {
        const {
          data: {
            updateRuleChildren: { errors },
          },
        } = await updateRuleChildren({
          variables: {
            childType,
            id: childId,
            position: newPosition,
          },
        });

        if (errors.length) {
          return toastMessage('error', errors.join(', '));
        }

        toastMessage('success', 'Action successful');
      } catch (error) {
        toastMessage('error', error.message);
      }
    },
    [toastMessage, updateRuleChildren]
  );

  const onRowDragEnd = useCallback(
    ({ source, destination }) => {
      if (!destination || source.index === destination.index) return;

      const srcRow = rows[source.index];
      const destRow = rows[destination.index];

      setRows(reorderRows(rows, source.index, destination.index));

      updateChildPosition(srcRow.id, srcRow.type, destRow.position);
    },
    [rows, updateChildPosition]
  );

  return (
    <>
      <Backdrop open={isUpdatingRuleChildren}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <RuleChildrenTableView
        rows={rows}
        createSi={createSi}
        onRowDragEnd={onRowDragEnd}
        createReq={createRequirement}
        isChildrenMutable={isChildrenMutable}
      />

      {/* Requirement Related Components */}

      {formProps.type === 'requirement-create-form' && (
        <RequirementCreateForm
          onClose={clearFormProps}
          ruleVersion={ruleVersion}
          breadCrumbsLabels={ruleAncestors}
          fetchQueries={[
            {
              query: RULE_CHILDREN_QUERY,
              variables: { id: rule.id, startsAt: ruleVersion.startsAt },
            },
            { query: QUESTIONS_QUERY, variables: { regulatorId: rule.regulator.id } },
          ]}
        />
      )}

      <DeleteRequirementVersion {...reqVersionDeleteProps} ruleVersion={ruleVersion} />

      {formProps.type === 'req-version-upsert-form' && (
        <RequirementVersionUpsertForm
          onClose={clearFormProps}
          isEditing={formProps.isEditing}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          requirementVersion={formProps.requirement.version}
          requirementId={formProps.requirement.id}
        />
      )}

      {/* Supporting Information Related Components */}

      {formProps.type === 'si-create-form' && (
        <SupportingInformationCreateForm
          onClose={clearFormProps}
          ruleVersion={ruleVersion}
          breadCrumbsLabels={ruleAncestors}
          fetchQueries={[
            {
              query: RULE_CHILDREN_QUERY,
              variables: { id: rule.id, startsAt: ruleVersion?.startsAt },
            },
          ]}
        />
      )}

      <DeleteSupportingInformationVersion {...siVersionDeleteProps} ruleVersion={ruleVersion} />

      {formProps.type === 'si-version-upsert-form' && (
        <SupportingInformationVersionUpsertForm
          onClose={clearFormProps}
          isEditing={formProps.isEditing}
          refetchQueries={refetchQueries}
          breadCrumbsLabels={ruleAncestors}
          supportingInformationVersion={formProps.supportingInformation.version}
          supportingInformationId={formProps.supportingInformation.id}
        />
      )}
    </>
  );
};

export default RuleChildrenTable;
