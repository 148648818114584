import { useMutation } from '@apollo/client';
import { useState } from 'react';
import { useToast } from 'contexts/toast';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const VersionDeleteForm = ({
  Component,
  props,
  deleteMutationKey,
  mutationQuery,
  refetchQueries = [],
}) => {
  const toastMessage = useToast();
  const [errors, setErrors] = useState([]);
  const [allowEntityDeletion, setAllowEntityDeletion] = useState(false);
  const { id, versionsCount, handleClose, isOpen } = props;
  const [deleteVersion, { loading }] = useMutation(mutationQuery);

  const onClose = () => {
    handleClose();
    setErrors([]);
    setAllowEntityDeletion(false);
  };

  const handleSubmit = async () => {
    if (versionsCount === 1 && !allowEntityDeletion) {
      setAllowEntityDeletion(true);
      setErrors([
        'Warning: This is the only version of this resource. Deleting it will also delete the resource.',
      ]);

      return;
    }

    setErrors([]);

    try {
      const {
        data: {
          [deleteMutationKey]: { errors },
        },
      } = await deleteVersion({
        variables: { id, allowEntityDeletion },
        refetchQueries: refetchQueries,
      });

      if (errors.length) return setErrors(errors);

      onClose();
      toastMessage('success', 'Version deleted successfully');
    } catch (error) {
      setErrors([error.message]);
    }
  };

  if (loading) {
    return (
      <Backdrop
        data-testid="loading"
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Component
      isOpen={isOpen}
      errors={errors}
      handleClose={onClose}
      handleSubmit={handleSubmit}
      allowEntityDeletion={allowEntityDeletion}
    />
  );
};

export default VersionDeleteForm;
