import ascentLogo from 'assets/images/ascent-logo.png';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import BalanceIcon from '@mui/icons-material/Balance';
import IconButton from '@mui/material/IconButton';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Drawer from '@mui/material/Drawer';
import useRoutes from 'hooks/useRoutes';

const ListItemLink = ({ icon, primary, to, onClick }) => (
  <ListItem button component={RouterLink} to={to} onClick={onClick}>
    <ListItemIcon color="secondary.contrastText">{icon}</ListItemIcon>
    <ListItemText primary={primary} />
  </ListItem>
);

const NavDrawer = ({ isNavDrawerOpen, navDrawerWidth = 240, toggleNavDrawer }) => {
  const sx = useSx({ isNavDrawerOpen, navDrawerWidth });
  const routes = useRoutes();

  const onClick = () => {
    toggleNavDrawer();
  };

  return (
    <Drawer open={isNavDrawerOpen} sx={sx.drawer} onClose={toggleNavDrawer}>
      <Box sx={sx.header}>
        <Link component={RouterLink} to={routes.regulators.path} sx={sx.logo} onClick={onClick}>
          <img src={ascentLogo} alt="Ascent" width="100%" height="100%" />
        </Link>
        <IconButton
          data-testid="navDrawer-toggler"
          aria-label="toggle drawer"
          edge="start"
          onClick={toggleNavDrawer}
        >
          <MenuOpenIcon />
        </IconButton>
      </Box>
      <Divider />
      <List>
        <ListItemLink
          to={routes.regulators.path}
          primary="Regulators"
          icon={<BalanceIcon />}
          onClick={onClick}
        />
      </List>
    </Drawer>
  );
};

const useSx = ({ navDrawerWidth }) => ({
  drawer: {
    '& .MuiDrawer-paper': {
      width: navDrawerWidth,
      boxSizing: 'border-box',
      overflow: 'hidden',
    },
  },
  header: (theme) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  }),
  logo: (theme) => ({
    margin: theme.spacing(2.75, 7, 2.75, 2),
    height: 20,
  }),
});

export default NavDrawer;
