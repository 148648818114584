import { range } from 'lodash';
import { useParams, Link } from 'react-router-dom';
import { useState } from 'react';
import formatDate from 'utils/formatDate';
import useCopyToClipboard from 'hooks/useCopyToClipboard';

import DismissableAlert from 'components/Alerts/DismissableAlert';
import StaticAlert from 'components/Alerts/StaticAlert';
import BreadCrumbs from 'components/Breadcrumbs';
import QuestionsFieldSet from 'components/Forms/QuestionsFieldSet';
import { FormModalContentLeft } from 'components/Forms/FormModal';
import DeleteRuleVersionConfirmation from 'components/Rules/DeleteRuleVersionConfirmation';
import RuleChildrenTable from 'components/Rules/RuleChildrenTable';
import RuleVersionUpsertForm from 'components/Rules/RuleVersionUpsertForm';

import AddBoxIcon from '@mui/icons-material/AddBox';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Skeleton from '@mui/material/Skeleton';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import DeleteIcon from '@mui/icons-material/Delete';

import useRoutes from 'hooks/useRoutes';
import { useEnv } from 'contexts/env';
import { useLocationSearch } from 'utils/searchParams';

const RuleLoader = () => (
  <Box arial-label="rule loader" sx={{ width: '100%', m: 4 }} data-testid="loading">
    <Skeleton width="50%" height="3.5em" />
    <Skeleton width="13em" height="3.5em" />
    <Skeleton width="25em" sx={{ marginBottom: '1em' }} />
    <Box sx={{ marginBottom: '3em' }}>
      {range(0, 20).map((i) => (
        <Skeleton width="100%" key={i} />
      ))}
    </Box>
  </Box>
);

const statusChipColorMap = {
  errored: 'error',
  completed: 'success',
  queued: 'warning',
};

const RuleVersionStatusChip = ({ status }) => (
  <Chip
    sx={{ marginX: 2, cursor: 'inherit' }}
    size="small"
    color={statusChipColorMap[status]}
    label={status || 'manual'}
  />
);

const RuleVersionMenuItemContent = ({ rule, ruleVersion, ...restProps }) => (
  <Box display="flex" justifyContent="space-between" {...restProps}>
    <Typography variant="body2">
      {formatDate(ruleVersion.startsAt)}{' '}
      {ruleVersion.endsAt && `- ${formatDate(ruleVersion.endsAt)}`}
      {ruleVersion.id === rule.currentVersion?.id && ' (current)'}
    </Typography>
    <RuleVersionStatusChip status={ruleVersion.enrichmentStatus} />
  </Box>
);

const LegacyRuleView = ({
  ruleChildren,
  fetchChildrenError,
  fetchRuleVersion,
  form,
  updateRule,
  isLoadingChildren,
  isLoadingRule,
  isLoadingRuleVersion,
  isUpdatingRule = false,
  questions,
  rule,
  ruleVersion,
  refetchQueries,
  refetchRuleQueries,
}) => {
  const sx = useSx();
  const routes = useRoutes();
  const copyToClipboard = useCopyToClipboard();
  const { regulatorId } = useParams();
  const ruleId = rule?.id;
  const [ruleVersionUpsertFormProps, setRuleVersionUpsertFormProps] = useState({ show: false });
  const env = useEnv();
  const [isDialogOpen, setDialogOpen] = useState(false);
  const onClose = () => setDialogOpen(false);

  const { highlightIds } = useLocationSearch().params;

  const queryString = (highlightIds?.split(',') || [])
    .map((id) => `highlightIds[]=${id}`)
    .join('&');
  const newRoute = routes.generateUrl('regulator.rule', {
    regulatorId,
    ruleId,
    params: queryString,
  });

  if (isLoadingRule || !rule || !ruleVersion || isLoadingChildren || isLoadingRuleVersion)
    return <RuleLoader />;

  const copyRuleVersionId = () =>
    copyToClipboard(ruleVersion.id, `Copied ${ruleVersion.__typename} ID`);

  const clearRuleVersionUpsertFormProps = () => setRuleVersionUpsertFormProps({ show: false });

  const addRuleVersion = () =>
    setRuleVersionUpsertFormProps({
      show: true,
      mode: 'create',
      rule,
      ruleVersion: {
        number: ruleVersion.number,
        title: ruleVersion.title,
        text: ruleVersion.text,
      },
    });

  const updateRuleVersion = () =>
    setRuleVersionUpsertFormProps({ show: true, mode: 'edit', rule, ruleVersion });

  const handleEnrichmentFlowChange = (e) =>
    updateRule({ enrichmentFlow: e.target.checked ? 'auto' : 'manual' });

  const {
    hasInvalidChildren,
    hasInvalidStartsAtChildren,
    hasInvalidEndsAtChildren,
    hasInvalidPositionChildren,
  } = rule;
  const ancestors = [...rule.ancestors.map((ancestor) => ancestor.name), rule.name];

  const isInManualFlow = rule.enrichmentFlow === 'manual';
  const isInManualMode = isInManualFlow;
  const isInAutoMode = !isInManualMode;

  const AutoEnrichmentToggle = () => (
    <FormControlLabel
      label={
        <Typography variant="body2">
          Automatic Enrichment {isInManualMode ? 'Disabled' : 'Enabled'}
        </Typography>
      }
      labelPlacement="start"
      control={
        <Switch
          color="secondary"
          disabled={isUpdatingRule}
          checked={isInAutoMode}
          onChange={handleEnrichmentFlowChange}
        />
      }
      sx={{ margin: 0 }}
    />
  );

  return (
    <Box sx={sx.root}>
      <Backdrop open={isUpdatingRule} sx={sx.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {ruleVersionUpsertFormProps.show && (
        <RuleVersionUpsertForm
          onClose={clearRuleVersionUpsertFormProps}
          breadCrumbsLabels={ancestors}
          refetchQueries={refetchRuleQueries}
          {...ruleVersionUpsertFormProps}
        />
      )}

      <Box sx={{ ...sx.section, ...sx.firstSection }}>
        {env?.FF_ALLOW_NEW_RULE_PAGE && (
          <Alert severity="info">
            The new Rule Page is available. &#20;
            <Link to={newRoute}>Go there now.</Link>
          </Alert>
        )}

        <Box display="flex" justifyContent="space-between" alignItems="center" sx={sx.title}>
          <Typography component="h2" variant="h3">
            {ruleVersion?.number} {ruleVersion?.title}
          </Typography>
          <Box display="flex" alignItems="center">
            <StaticAlert
              severity={hasInvalidChildren ? 'error' : 'warning'}
              visible={isInManualMode}
              sx={{ marginY: 0 }}
            >
              <AutoEnrichmentToggle />
            </StaticAlert>
            {isInAutoMode && <AutoEnrichmentToggle />}
          </Box>
        </Box>

        <StaticAlert
          children="Rule has children with invalid Start Dates. Please update any Reqs/SI below to have a Start Date that aligns with a RuleVersion's Start Date."
          severity="error"
          visible={hasInvalidStartsAtChildren}
        />

        <DismissableAlert
          children="Rule has children with potentially invalid End Dates. Please ensure that all Reqs/SI below that don't have End Dates are correct."
          confirmMessage="Please confirm that the data is correct or has been fixed."
          confirmTitle="Invalid End Dates"
          onClose={() => updateRule({ invalidEndsAtChildrenStatus: 'overridden' })}
          severity="error"
          visible={hasInvalidEndsAtChildren}
        />

        <DismissableAlert
          children="Rule has children with potentially invalid Positions. Please ensure that all Reqs/SI below have correct Positions."
          confirmMessage="Please confirm that the data is correct or has been fixed."
          confirmTitle="Invalid Positions"
          onClose={() => updateRule({ invalidPositionChildrenStatus: 'overridden' })}
          severity="error"
          visible={hasInvalidPositionChildren}
        />

        <BreadCrumbs labels={ancestors} />

        <Box marginBottom={1}>
          <Select
            autoWidth={true}
            defaultValue={ruleVersion.id}
            onChange={(e) => fetchRuleVersion({ id: e.target.value })}
            renderValue={(value) => (
              <RuleVersionMenuItemContent
                rule={rule}
                ruleVersion={rule.versions.nodes.find((i) => i.id === value)}
                marginTop={0.3}
              />
            )}
            value={ruleVersion.id}
            size="small"
            sx={{ marginRight: 3 }}
          >
            {(rule.versions.nodes || []).map((version) => (
              <MenuItem
                key={version.id}
                value={version.id}
                selected={version.id === ruleVersion?.id}
              >
                <RuleVersionMenuItemContent rule={rule} ruleVersion={version} />
              </MenuItem>
            ))}
          </Select>
          <Button
            aria-label="copy version id"
            onClick={() => copyRuleVersionId()}
            startIcon={<CopyAllIcon />}
            sx={sx.button}
          >
            Copy ID
          </Button>
          <Button
            aria-label="edit version"
            disabled={isInAutoMode}
            onClick={() => updateRuleVersion()}
            startIcon={<EditIcon />}
            sx={sx.button}
          >
            Edit Version
          </Button>
          <Button
            startIcon={<DeleteIcon />}
            sx={{ ...sx.btn, ...sx.button }}
            aria-label="delete version"
            onClick={() => setDialogOpen(true)}
            disabled={isInAutoMode}
          >
            Delete Version
          </Button>
          <DeleteRuleVersionConfirmation
            disabled={isInAutoMode}
            fetchRuleVersion={fetchRuleVersion}
            isOpen={isDialogOpen}
            onClose={onClose}
            refetchQueries={refetchQueries}
            ruleId={rule.id}
            versionId={ruleVersion?.id}
            versionsCount={rule.versions.nodes.length}
          />
          <Button
            aria-label="add version"
            onClick={() => addRuleVersion()}
            startIcon={<AddBoxIcon />}
            sx={sx.button}
          >
            Add Version
          </Button>
        </Box>

        <TextField
          sx={sx.ruleContent}
          value={ruleVersion?.text}
          variant="standard"
          multiline
          fullWidth
          disabled
        />
      </Box>

      <Divider />

      <Box sx={sx.section}>
        <FormModalContentLeft>
          <QuestionsFieldSet
            questions={questions}
            formProps={form.props}
            defaultOptions={rule?.answers || []}
          />
        </FormModalContentLeft>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            aria-label="Save Answers"
            variant="outlined"
            color="secondary"
            sx={{ mt: '1em', mr: 0 }}
            onClick={() => updateRule()}
          >
            Save
          </Button>
        </Box>
      </Box>

      <Divider />

      <Box sx={sx.section}>
        {fetchChildrenError ? (
          <Alert severity="error">Something went wrong :(</Alert>
        ) : (
          <>
            <RuleChildrenTable
              rule={rule}
              ruleVersion={ruleVersion}
              ruleChildren={ruleChildren}
              ruleAncestors={ancestors}
              regulatorQuestions={questions}
              isChildrenMutable={isInManualMode}
            />
          </>
        )}
      </Box>
    </Box>
  );
};

const useSx = () => ({
  btn: {
    border: 'none',
    marginRight: '15px',
    color: 'blue.200',
    padding: '8px 0',
    '&:hover': {
      border: 'none',
    },
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: (theme) => theme.palette.primary.background,
  },
  root: {
    overflowX: 'hidden',
    width: '100%',
    marginTop: 2,
  },
  title: {
    marginBottom: 1,
  },
  button: {
    marginLeft: 1,
    color: 'blue.200',
  },
  section: {
    padding: 6,
    paddingBottom: 8,
    flexGrow: 1,
    overflowX: 'hidden',
  },
  firstSection: {
    paddingTop: 4,
  },
  ruleContent: {
    marginTop: 4,
    '& .MuiInput-root, & .MuiInput-root:before, & .MuiInput-root:after, & textarea': {
      color: '#333333 !important',
      textFillColor: '#333333 !important',
      border: '0 !important',
      cursor: 'text',
    },
  },
});

export default LegacyRuleView;
