import { gql } from '@apollo/client';

export const REQUIREMENT_QUERY = gql`
  query Requirement($id: ID!) {
    requirement(id: $id) {
      id
      aic
      baseContent
      endsAt
      frequency
      startsAt
      summary
      number
      taskContent
    }
  }
`;

export const CREATE_REQUIREMENT_MUTATION = gql`
  mutation CreateRequirement(
    $aic: String!
    $endsAt: ISO8601Date
    $frequency: String!
    $ruleId: String!
    $startsAt: ISO8601Date!
    $content: String!
    $summary: String!
    $number: String
    $position: Int
  ) {
    createRequirement(
      input: {
        aic: $aic
        baseContent: $content
        endsAt: $endsAt
        frequency: $frequency
        number: $number
        ruleId: $ruleId
        startsAt: $startsAt
        summary: $summary
        taskContent: $content
        position: $position
      }
    ) {
      requirement {
        id
        aic
        frequency
        summary
        number
        answers {
          id
          content
        }
      }
      errors
    }
  }
`;

export const REQUIREMENT_VERSION_CREATE_MUTATION = gql`
  mutation CreateRequirementVersion(
    $aic: String!
    $content: String!
    $endsAt: ISO8601Date
    $frequency: String
    $number: String!
    $requirementId: ID!
    $startsAt: ISO8601Date!
    $summary: String!
  ) {
    createRequirementVersion(
      input: {
        aic: $aic
        baseContent: $content
        endsAt: $endsAt
        frequency: $frequency
        number: $number
        requirementId: $requirementId
        startsAt: $startsAt
        summary: $summary
        taskContent: $content
      }
    ) {
      requirementVersion {
        id
        aic
        baseContent
        endsAt
        frequency
        startsAt
        summary
        taskContent
      }
      errors
    }
  }
`;

export const REQUIREMENT_VERSION_UPDATE_MUTATION = gql`
  mutation UpdateRequirementVersion(
    $id: ID!
    $aic: String
    $number: String
    $frequency: String
    $endsAt: ISO8601Date
    $startsAt: ISO8601Date!
    $summary: String
    $content: String!
    $detachOverride: Boolean
  ) {
    updateRequirementVersion(
      input: {
        id: $id
        aic: $aic
        number: $number
        frequency: $frequency
        endsAt: $endsAt
        startsAt: $startsAt
        summary: $summary
        taskContent: $content
        baseContent: $content
        detachOverride: $detachOverride
      }
    ) {
      requirementVersion {
        id
        aic
        baseContent
        endsAt
        frequency
        startsAt
        summary
        taskContent
      }
      errors
    }
  }
`;

export const REQUIREMENT_VERSION_DELETE_MUTATION = gql`
  mutation DeleteRequirementVersion($id: ID!, $allowEntityDeletion: Boolean) {
    deleteRequirementVersion(input: { id: $id, allowEntityDeletion: $allowEntityDeletion }) {
      errors
    }
  }
`;
