import { REQUIREMENT_VERSION_DELETE_MUTATION } from 'components/Requirements/queries';
import { RULE_CHILDREN_QUERY } from 'components/Rules/queries';
import useQueryParams from 'hooks/useQuery';
import VersionDeleteForm from 'components/VersionDeleteForm';
import DeleteRequirementVersionView from 'components/Requirements/DeleteRequirementVersion/index.view';

const DeleteRequirementVersion = (props) => {
  const queryParams = useQueryParams();
  const ruleId = queryParams.get('ruleId');

  return (
    <VersionDeleteForm
      Component={DeleteRequirementVersionView}
      props={props}
      deleteMutationKey="deleteRequirementVersion"
      mutationQuery={REQUIREMENT_VERSION_DELETE_MUTATION}
      refetchQueries={[
        {
          query: RULE_CHILDREN_QUERY,
          variables: { id: ruleId, startsAt: props.ruleVersion?.startsAt },
        },
      ]}
    />
  );
};

export default DeleteRequirementVersion;
