import React, { createContext, useContext, useEffect, useState } from 'react';
import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { useToast } from 'contexts/toast';
import { useEnv } from '../env';

export const ApiContext = createContext({
  api: null,
});

const createApi = (token) => {
  return new ApolloClient({
    connectToDevTools: true,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            rule: {
              merge: true,
            },
          },
        },
      },
    }),
    headers: { Authorization: `Bearer ${token}` },
    uri: `${window.location.protocol}//${window.location.host}/graphql`,
  });
};

export const ApiProvider = ({ children }) => {
  const [api, setApi] = useState(null);
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const toastMessage = useToast();
  const env = useEnv();

  useEffect(() => {
    (async () => {
      let token = null;

      try {
        if (isAuthenticated) {
          token = await getAccessTokenSilently({
            audience: env.AUTH0_AUDIENCE,
            scope: 'modify:*',
          });
        }

        setApi(createApi(token));
      } catch (error) {
        toastMessage('error', error.message);
      }
    })();

    return () => setApi(null);
  }, [getAccessTokenSilently, isAuthenticated, setApi, env.AUTH0_AUDIENCE, toastMessage]);

  if (!api) return null;

  return (
    <ApiContext.Provider value={{ api }}>
      <ApolloProvider client={api}>{children}</ApolloProvider>
    </ApiContext.Provider>
  );
};

export const MockedApiProvider = ({ api, children }) => (
  <ApiContext.Provider value={{ api }}>{children}</ApiContext.Provider>
);

export const useApi = () => useContext(ApiContext);
