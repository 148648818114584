import { useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';
import useQueryParams from 'hooks/useQuery';
import { useToast } from 'contexts/toast';

import RequirementCreateFormView from 'components/Requirements/RequirementCreateForm/index.view';
import { CREATE_REQUIREMENT_MUTATION } from 'components/Requirements/queries';
import useForm from 'hooks/useForm';
import { useState } from 'react';
import { toISODateString, toLocalizedDate } from 'utils/dateAndTime';

const buildFormDefaults = (ruleVersion, disableDates) => ({
  aic: '',
  frequency: '',
  number: '',
  startsAt: toLocalizedDate(ruleVersion.startsAt),
  endsAt: disableDates ? null : toLocalizedDate(ruleVersion.endsAt),
  summary: '',
  content: '',
});

const RequirementCreateForm = ({
  onClose,
  ruleVersion,
  breadCrumbsLabels = [],
  questions = [],
  disableDates = false,
  requirement = {}, // contains only values for fields that needs to be passed to the mutation but no input field
  refetchQueries = [],
}) => {
  const toastMessage = useToast();
  const queryParams = useQueryParams();
  const pathParams = useParams();
  const ruleId = queryParams.get('ruleId') || pathParams.ruleId;
  const form = useForm({ ...buildFormDefaults(ruleVersion, disableDates), ...requirement });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [createRequirement] = useMutation(CREATE_REQUIREMENT_MUTATION);
  const handleSubmit = async () => {
    form.setErrors([]);
    setIsSubmitting(true);

    try {
      const {
        data: {
          createRequirement: { errors },
        },
      } = await createRequirement({
        variables: {
          ruleId,
          ...form.values,
          startsAt: toISODateString(form.values.startsAt),
          endsAt: toISODateString(form.values.endsAt),
        },
        refetchQueries: refetchQueries,
        update(cache) {
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'rule' });
          cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
        },
      });

      setIsSubmitting(false);
      if (errors.length) return form.setErrors(errors);
      onClose();
      toastMessage('success', 'Requirement created successfully.');
    } catch (error) {
      form.setErrors([error.message]);
      setIsSubmitting(false);
    }
  };

  return (
    <RequirementCreateFormView
      onClose={onClose}
      handleSubmit={handleSubmit}
      breadCrumbsLabels={breadCrumbsLabels}
      questions={questions}
      isSubmitting={isSubmitting}
      isEditing={false}
      form={form}
      disableDates={disableDates}
    />
  );
};

export default RequirementCreateForm;
