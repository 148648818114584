// Libs
import { useParams, Link, useLocation } from 'react-router-dom';

// MUI Components
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

// MUI Icons
import CopyAllIcon from '@mui/icons-material/CopyAllOutlined';
import ModelTrainingIcon from '@mui/icons-material/ModelTraining';

// Local Components
import BreadCrumbs from 'components/Breadcrumbs';
import ContextMenu from 'components/ContextMenu';
import DefectToolTip from 'components/Rules/DefectToolTip';
import RuleVersionExplorer from 'components/Rules/RuleVersionExplorer';
import StaticAlert from 'components/Alerts/StaticAlert';

// Hooks & Contexts
import useCopyToClipboard from 'hooks/useCopyToClipboard';
import useRoutes from 'hooks/useRoutes';
import { useEnv } from 'contexts/env';

const RuleView = ({ rule, isUpdating, setIsUpdating, onToggleEnrichmentFlow }) => {
  const sx = useSx();
  const env = useEnv();
  const routes = useRoutes();
  const { regulatorId, ruleId } = useParams();

  const location = useLocation();
  const highlightIds = new URLSearchParams(location.search).getAll('highlightIds[]');
  const oldRoute = routes.generateUrl('regulator.legacyRule', {
    regulatorId,
    ruleId,
    params: `&highlightIds=${highlightIds?.join(',')}`,
  });

  const copyToClipboard = useCopyToClipboard();

  const handleCopyId = (entityType, id) => {
    copyToClipboard(id, `Copied ${entityType} ID`);
  };

  return (
    <Box sx={sx.root}>
      <Backdrop open={isUpdating} sx={sx.backdrop}>
        <CircularProgress color="inherit" />
      </Backdrop>

      {!env.FF_FORCE_NEW_RULE_PAGE && (
        <Alert severity="info">
          The old Rule Page is available. &#20;
          <Link to={oldRoute}>Go there now.</Link>
        </Alert>
      )}
      <BreadCrumb rule={rule} />
      <Box sx={sx.title}>
        <Box display="flex" sx={sx.nameAndDefect}>
          <Box>
            <Typography component="h2" variant="h3" sx={sx.name}>
              {rule.name}
            </Typography>
          </Box>
          <DefectToolTip defects={rule.defects} />
        </Box>

        <Stack direction="row" spacing={2} alignItems="center">
          {['queued', 'started'].includes(rule.defectCheckStatus) && (
            <Chip
              color="error"
              data-testid="checking-for-defects"
              icon={<ModelTrainingIcon />}
              label="Checking for defects"
            />
          )}

          <Box sx={sx.toggleAndContextContainer}>
            <AutoEnrichmentDisplay
              rule={rule}
              isUpdating={isUpdating}
              onToggleEnrichmentFlow={onToggleEnrichmentFlow}
            />
            <ContextMenu identifier={rule.id} title={'Rule'}>
              <MenuItem onClick={() => handleCopyId('Rule', rule.id)}>
                <ListItemIcon>
                  <CopyAllIcon />
                </ListItemIcon>
                <ListItemText>Copy Rule ID</ListItemText>
              </MenuItem>
            </ContextMenu>
          </Box>
        </Stack>
      </Box>
      <RuleVersionExplorer setIsUpdating={setIsUpdating} />
    </Box>
  );
};

const AutoEnrichmentDisplay = ({ rule, isUpdating, onToggleEnrichmentFlow }) => {
  if (rule?.enrichmentFlow === 'auto')
    return (
      <AutoEnrichmentSwitch
        checked={true}
        disabled={isUpdating}
        onChange={onToggleEnrichmentFlow}
      />
    );

  return (
    <StaticAlert severity="warning" sx={{ marginY: 0 }}>
      <AutoEnrichmentSwitch
        checked={false}
        disabled={isUpdating}
        onChange={onToggleEnrichmentFlow}
      />
    </StaticAlert>
  );
};

const AutoEnrichmentSwitch = ({ checked, onChange, disabled }) => (
  <FormControlLabel
    data-testid="auto-enrichment-switch"
    sx={{ margin: 0 }}
    labelPlacement="start"
    label={
      <Typography variant="body2">
        Automatic Enrichment {checked ? 'Enabled' : 'Disabled'}
      </Typography>
    }
    control={<Switch color="secondary" disabled={disabled} checked={checked} onChange={onChange} />}
  />
);

const BreadCrumb = ({ rule }) => {
  const ancestors = [...rule.ancestors.map((ancestor) => ancestor.name), null];

  return <BreadCrumbs labels={ancestors} />;
};

const useSx = () => ({
  backdrop: {
    zIndex: (theme) => theme.zIndex.drawer + 1,
    color: (theme) => theme.palette.primary.background,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    padding: 4,
    overflow: 'hidden',
  },
  title: {
    display: 'flex',
    height: '3rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '0.5rem',
    marginTop: '0.5rem',
  },
  name: {
    marginRight: 1,
    lineHeight: 1.2,
  },
  nameAndDefect: {
    display: 'flex',
    alignItems: 'center',
  },
  toggleAndContextContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export default RuleView;
