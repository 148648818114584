import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import { AicField } from 'components/Forms';
import {
  FormModal,
  FormModalAlert,
  FormModalHeader,
  FormModalContent,
  FormModalBreadCrumbs,
  FormModalContentLeft,
  FormModalContentRight,
} from 'components/Forms/FormModal';

const AIC_MAPPING = [
  { label: 'D', value: 'definition' },
  { label: 'CL', value: 'clarification' },
  { label: 'E', value: 'exemption' },
  { label: 'Z', value: 'catchall' },
  { label: 'G', value: 'government' },
  { label: 'AT', value: 'appendix_text' },
];

const SupportingInformationVersionUpsertFormView = ({
  form,
  onClose,
  isEditing,
  isSubmitting,
  handleSubmit,
  breadCrumbsLabels,
  disableDates,
}) => (
  <FormModal data-testid="supporting-information-version-upsert-form" isOpen>
    <FormModalHeader
      title={`${isEditing ? 'Edit' : 'New'} SupportingInformation Version`}
      submitBtnText="Save SupportingInformation Version"
      onClose={onClose}
      onSubmit={handleSubmit}
      isSubmitting={isSubmitting}
      onReset={() => form.reset()}
      canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
    />
    {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
    <FormModalBreadCrumbs labels={breadCrumbsLabels} />
    <FormModalContent>
      <FormModalContentLeft>
        <TextField size="small" label="Number" value={form.values.number} {...form.props.number} />
        <DatePicker
          {...form.props.startsAt}
          label="Start Date"
          value={form.values.startsAt}
          renderInput={(params) => <TextField size="small" {...params} />}
          disabled={disableDates}
        />
        <DatePicker
          {...form.props.endsAt}
          label="End Date"
          value={form.values.endsAt}
          renderInput={(params) => <TextField size="small" {...params} />}
          disabled={disableDates}
        />
        <AicField value={form.values.aic} aicMapping={AIC_MAPPING} {...form.props.aic} />
      </FormModalContentLeft>
      <FormModalContentRight>
        <TextField
          multiline
          label="Content"
          value={form.values.content}
          sx={{ mt: 0.8, mb: 2, width: '100%' }}
          {...form.props.content}
        />
      </FormModalContentRight>
    </FormModalContent>
  </FormModal>
);

export default SupportingInformationVersionUpsertFormView;
