// Libs
import { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useParams } from 'react-router-dom';

// MUI Components
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// Local Components
import RuleView from './index.view';

// Static Assets
import { NEO_RULE_QUERY, UPDATE_RULE_MUTATION } from '../queries';

// Hooks
import useForm from 'hooks/useForm';

// Contexts
import { useToast } from 'contexts/toast';

// No Results Notifications
const Centered = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" width="100%">
    {children}
  </Box>
);

const SelectRule = () => (
  <Centered>
    <Typography variant="h1">Select a rule to continue</Typography>
  </Centered>
);

const NotFound = () => (
  <Centered>
    <Alert severity="error">Unable to find the selected Rule</Alert>
  </Centered>
);

const FORM_DEFAULTS = {};

const Rule = () => {
  const { ruleId } = useParams();

  const toastMessage = useToast();
  const [isUpdating, setIsUpdating] = useState(false);
  const form = useForm(FORM_DEFAULTS);

  const {
    loading: isLoadingRule,
    error: fetchRuleError,
    data: { rule } = {},
  } = useQuery(NEO_RULE_QUERY, {
    variables: { id: ruleId },
    skip: !ruleId,
  });

  const [updateRule] = useMutation(UPDATE_RULE_MUTATION);
  const handleRuleUpdate = async (values) => {
    form.setErrors([]);
    setIsUpdating(true);

    try {
      const {
        data: {
          updateRule: { errors },
        },
      } = await updateRule({
        variables: {
          id: ruleId,
          ...values,
        },
        refetchQueries: [
          {
            query: NEO_RULE_QUERY,
            variables: { id: ruleId },
          },
        ],
      });

      setIsUpdating(false);
      if (errors.length) {
        errors.map((error) => toastMessage('error', error));

        return form.setErrors(errors);
      }
      toastMessage('success', 'Rule updated successfully');
    } catch (error) {
      form.setErrors([error.message]);
      setIsUpdating(false);
      toastMessage('error', error.message);
    }
  };

  const onToggleEnrichmentFlow = (event) => {
    handleRuleUpdate({ enrichmentFlow: event.target.checked ? 'auto' : 'manual' });
  };

  if (fetchRuleError)
    return (
      <Centered>
        <Alert severity="error">Something went wrong :(</Alert>
      </Centered>
    );
  if (!ruleId) return <SelectRule />;
  if (isLoadingRule) return null;

  if (!isLoadingRule && !rule) return <NotFound />;

  return (
    <RuleView
      rule={rule}
      onToggleEnrichmentFlow={onToggleEnrichmentFlow}
      isUpdating={isUpdating}
      setIsUpdating={setIsUpdating}
    />
  );
};

export default Rule;
