import { useState } from 'react';

export const usePopupState = ({ ref = null }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (ref?.current && ref.current.contains(event.target)) return;

    setIsOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Escape') {
      setIsOpen(false);
    }
  };

  return { isOpen, handleToggle, handleClose, handleListKeyDown };
};
