// Libs
import { useState } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { useMutation } from '@apollo/client';

// MUI Components
import Alert from '@mui/material/Alert';

// Local Components
import { ConfirmDialog } from 'components/Forms';

// Contexts & Hooks
import { useToast } from 'contexts/toast';
import useRoutes from 'hooks/useRoutes';

// Queries
import { DELETE_RULE_VERSION_MUTATION } from 'components/Rules/queries';

const DeleteRuleVersionConfirmation = ({
  isOpen,
  onClose,
  refetchQueries = [],
  ruleId,
  versionId,
  versionsCount,
}) => {
  const routes = useRoutes();
  const toastMessage = useToast();
  const { regulatorId } = useParams();
  const [redirectPath, setRedirectPath] = useState();

  const isLastVersion = versionsCount === 1;

  const [deleteRuleVersion, { loading }] = useMutation(DELETE_RULE_VERSION_MUTATION, {
    variables: { id: versionId, allowEntityDeletion: true, detachOnDestroy: true },
    refetchQueries: refetchQueries,
    update(cache) {
      cache.evict({ id: 'ROOT_QUERY', fieldName: 'ruleVersion' });
    },
  });

  const handleConfirm = async () => {
    try {
      const {
        data: {
          deleteRuleVersion: { errors },
        },
      } = await deleteRuleVersion();

      if (errors.length) return toastMessage('error', errors[0]);

      setRedirectPath(
        isLastVersion
          ? routes.generateUrl('regulator', { regulatorId })
          : routes.generateUrl('rule', { regulatorId, ruleId })
      );

      onClose();
      toastMessage('success', 'Success');
    } catch (error) {
      toastMessage('error', error.message);
    }
  };

  if (redirectPath) return <Navigate to={redirectPath} />;

  return (
    <ConfirmDialog
      isOpen={isOpen}
      title="Delete Rule Version"
      description="Are you sure you want to delete this Rule Version?"
      onConfirm={handleConfirm}
      onCancel={onClose}
      confirmText={`Delete ${isLastVersion ? 'Anyway' : ''}`}
      isSubmitting={loading}
    >
      {isLastVersion && (
        <Alert severity="warning" sx={{ alignItems: 'center', marginTop: '1em' }}>
          The associated Rule will be deleted as it contains only this version.
        </Alert>
      )}
    </ConfirmDialog>
  );
};

export default DeleteRuleVersionConfirmation;
