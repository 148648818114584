import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs';
import { ReactComponent as BreadcrumbsIcon } from 'assets/images/breadcrumbIcon.svg';

const BreadCrumbs = ({ labels }) => (
  <Box sx={sx.breadcrumbs}>
    <BreadcrumbsIcon />
    <StyledBreadcrumbs aria-label="breadcrumb" sx={{ ml: 1.5 }}>
      {labels.map((label, i) => (
        <CustomTooltip title={label} key={i}>
          <Typography color="text.primary" variant="body2" sx={{ fontSize: '0.8em' }}>
            {label}
          </Typography>
        </CustomTooltip>
      ))}
    </StyledBreadcrumbs>
  </Box>
);

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: '40em',
    fontSize: '15px',
  },
});

const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  marginBottom: 0,

  [`& .${breadcrumbsClasses.li}`]: {
    maxWidth: '30em',
    whiteSpace: 'nowrap',

    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

const sx = {
  breadcrumbs: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '0.66rem 0 0 0',
    fontSize: '1em',
  },
};

export default BreadCrumbs;
