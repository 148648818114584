import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Gray } from 'components/App/colors';
import BreadCrumbs from 'components/Breadcrumbs';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import MultiLineAlert from 'components/Alerts/MultiLineAlert';

export const FormModal = ({ isOpen, children }) => (
  <Modal open={isOpen}>
    <Box sx={sx.root}>{children}</Box>
  </Modal>
);

export const FormModalHeader = ({
  title,
  canSubmit = false,
  isSubmitting = false,
  submitBtnText = 'Submit',
  onClose = () => {},
  onReset = () => {},
  onSubmit = () => {},
}) => (
  <Box sx={sx.header}>
    <Box sx={sx.headerCloseBtn}>
      <IconButton onClick={onClose} sx={{ color: Gray[700] }} aria-label="close-icon-button">
        <CloseIcon />
      </IconButton>
    </Box>
    <Divider flexItem variant="middle" orientation="vertical" sx={sx.headerDivider} />
    <Box sx={sx.headerTitle}>
      <Typography sx={{ fontWeight: 700, color: Gray[700] }}>{title}</Typography>
    </Box>
    {isSubmitting && (
      <CircularProgress data-testid="progress" color="success" size={23} sx={{ mr: 5 }} />
    )}
    <Button color="secondary" sx={{ mr: 3 }} onClick={onReset}>
      Reset
    </Button>
    <Button
      color="secondary"
      variant="contained"
      onClick={onSubmit}
      disabled={!canSubmit}
      sx={{ color: 'white', paddingX: 2 }}
    >
      {submitBtnText}
    </Button>
  </Box>
);

export const FormModalAlert = (props) => <MultiLineAlert {...props} sx={sx.alert} />;

export const FormModalBreadCrumbs = (props) => (
  <Box sx={sx.breadcrumbs}>
    <BreadCrumbs {...props} />
  </Box>
);

export const FormModalContent = ({ children }) => <Box sx={sx.content}>{children}</Box>;

export const FormModalContentLeft = ({ children }) => <Box sx={sx.contentLeft}>{children}</Box>;

export const FormModalContentRight = ({ children }) => <Box sx={sx.contentRight}>{children}</Box>;

const sx = {
  root: {
    top: '3rem',
    left: '3rem',
    display: 'flex',
    overflow: 'hidden',
    position: 'absolute',
    background: Gray[100],
    flexDirection: 'column',
    height: 'calc(100% - 6rem)',
    width: 'calc(100% - 6rem)',
  },
  alert: {
    width: '100%',
    borderBottom: `0.05em solid ${Gray[300]}`,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    overflow: 'hidden',
    padding: '0.8rem 1.5rem 0.8rem 1em',
    borderBottom: `0.08em solid ${Gray[300]}`,
  },
  headerTitle: {
    flexGrow: 1,
    marginLeft: '1.2em',
  },
  headerDivider: {
    mt: -0.2,
    height: '2rem',
  },
  headerCloseBtn: {
    marginRight: '0.5rem',
  },
  content: (theme) => ({
    display: 'flex',
    columnGap: '1.5rem',
    overflowY: 'auto',
    padding: '0 1.2rem',
    margin: '1.5rem auto',
    width: 'calc(100% - 2.4rem)',
    height: 'calc(100% - 3rem)',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  }),
  contentLeft: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '1.5rem',
  },
  contentRight: {
    flex: 2,
  },
  breadcrumbs: {
    padding: '2rem 2rem 0',
  },
};
