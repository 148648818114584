import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { AicField } from 'components/Forms';
import {
  FormModal,
  FormModalAlert,
  FormModalHeader,
  FormModalContent,
  FormModalBreadCrumbs,
  FormModalContentLeft,
  FormModalContentRight,
} from 'components/Forms/FormModal';

const AIC_MAPPING = [
  { label: 'RA', value: 'RA' },
  { label: 'RIC', value: 'RIC' },
  { label: 'RM', value: 'RM' },
];

export const RequirementVersionUpsertFormView = ({
  form,
  onClose,
  isEditing,
  isSubmitting,
  handleSubmit,
  breadCrumbsLabels,
  disableDates,
}) => {
  const sx = useSx();

  return (
    <FormModal data-testid="requirement-version-upsert-form" isOpen>
      <FormModalHeader
        title={`${isEditing ? 'Edit' : 'New'} Requirement Version`}
        submitBtnText="Save Requirement Version"
        onClose={onClose}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        onReset={() => form.reset()}
        canSubmit={!isSubmitting && (form.hasChanges || !isEditing)}
      />
      {form.errors.length > 0 && <FormModalAlert severity="error" messages={form.errors} />}
      <FormModalBreadCrumbs labels={breadCrumbsLabels} />
      <FormModalContent>
        <FormModalContentLeft>
          <TextField
            size="small"
            label="Number"
            sx={sx.field}
            value={form.values.number}
            {...form.props.number}
          />
          <TextField
            multiline
            size="small"
            label="Summary"
            sx={sx.field}
            value={form.values.summary}
            {...form.props.summary}
          />
          <DatePicker
            {...form.props.startsAt}
            label="Start Date"
            value={form.values.startsAt}
            renderInput={(params) => <TextField size="small" {...params} />}
            disabled={disableDates}
          />
          <DatePicker
            {...form.props.endsAt}
            label="End Date"
            value={form.values.endsAt}
            renderInput={(params) => <TextField size="small" {...params} />}
            disabled={disableDates}
          />
          <FormControl size="small" fullWidth sx={sx.field}>
            <InputLabel id="frequency-label" sx={{ mt: 0.8 }}>
              Frequency
            </InputLabel>
            <Select
              size="small"
              id="frequency"
              label="Frequency"
              labelId="frequency-label"
              sx={sx.field}
              value={form.values.frequency || 'No Frequency'}
              {...form.props.frequency}
            >
              <MenuItem value="No Frequency">No Frequency</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
              <MenuItem value="quarterly">Quarterly</MenuItem>
              <MenuItem value="annually">Annually</MenuItem>
              <MenuItem value="bi-annually">Bi-Annually</MenuItem>
            </Select>
          </FormControl>
          <AicField value={form.values.aic} aicMapping={AIC_MAPPING} {...form.props.aic} />
        </FormModalContentLeft>
        <FormModalContentRight>
          <TextField
            multiline
            label="Content"
            value={form.values.content}
            sx={{ mt: 0.8, mb: 2, width: '100%' }}
            {...form.props.content}
          />
        </FormModalContentRight>
      </FormModalContent>
    </FormModal>
  );
};

const useSx = () => ({
  field: {
    mt: 0.8,
    mb: 2,
    width: '100%',
  },
});

export default RequirementVersionUpsertFormView;
